let rutaLocal = "@/apps/DescargaCfdi/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/descarga-cfdi",
    redirect: "/descarga-cfdi/inicio",
  },
  {
    path: "/descarga-cfdi/inicio",
    name: "DescargaCfdiInicio",
    component: () =>
      import(/* webpackChunkName: "descargacfdihome" */ rutaLocal + "Lista"),
  },
  {
    path: "/descarga-cfdi/reportes",
    name: "DescargaCfdiReportes",
    component: () =>
      import(
        /* webpackChunkName: "descargacfdireportes" */ rutaLocal + "Reportes"
      ),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Descarga CFDIs",
  // descripcion aparece en el menú
  descripcion: "Descarga masiva del SAT",
  tarifa: 0,
  // Ícono principal
  icono: "codeWorking",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/descarga-cfdi/inicio",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/descarga-cfdi",
      label: "Descarga masiva de CFDIs",
      permiso: "",
      icono: "codeWorking",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

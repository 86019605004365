import Api from "@/common/Api/Api";

const routes = [
  {
    path: "/cmms",
    redirect: "/cmms/ordenes",
  },
  {
    path: "/cmms/agenda",
    component: () =>
      import(/* webpackChunkName: "agenda" */ "./Agenda/Agenda.vue"),
  },
  {
    path: "/cmms/cita/:id",
    name: "Cita",
    component: () => import(/* webpackChunkName: "cita" */ "./Agenda/Cita.vue"),
    props: true,
  },
  {
    path: "/cmms/ordenes",
    name: "Ordenes",
    component: () =>
      import(/* webpackChunkName: "ordenes" */ "./Ordenes/Lista.vue"),
  },
  {
    path: "/cmms/mediciones",
    name: "MedicionesRapidas",
    component: () =>
      import(
        /* webpackChunkName: "medicioneslista" */ "./Ordenes/Mediciones.vue"
      ),
  },
  {
    path: "/cmms/mediciones/:id",
    name: "MedicionId",
    component: () =>
      import(/* webpackChunkName: "medicionesid" */ "./Ordenes/Medicion.vue"),
    props: true,
  },
  {
    path: "/cmms/ordenes/:id",
    name: "Orden2",
    component: () =>
      import(/* webpackChunkName: "ordenid2" */ "./Ordenes/Orden.vue"),
    props: true,
  },
  {
    path: "/cmms/ordenes/:id/:idProyecto",
    name: "OrdenConProyecto",
    component: () =>
      import(/* webpackChunkName: "ordenconproyecto" */ "./Ordenes/Orden.vue"),
    props: true,
  },
  {
    path: "/cmms/reportes",
    name: "Reportes",
    component: () =>
      import(/* webpackChunkName: "reportes" */ "./Reportes/Reportes.vue"),
  },
  {
    path: "/cmms/inventario",
    name: "Inventario",
    component: () =>
      import(
        /* webpackChunkName: "inventario" */ "./Inventario/Inventario.vue"
      ),
  },
  {
    path: "/cmms/inventarioinsumos",
    name: "InventarioInsumos",
    component: () =>
      import(
        /* webpackChunkName: "inventarioinsumos" */ "./Inventario/InventarioInsumos.vue"
      ),
  },
  {
    path: "/cmms/catalogos/usuarios",
    name: "CatalogoUsuarios",
    component: () =>
      import(
        /* webpackChunkName: "catalogousuarios" */ "./Catalogos/Usuarios.vue"
      ),
  },
  {
    path: "/cmms/catalogos/usuarios/:id",
    name: "CatalogoUsuario",
    component: () =>
      import(
        /* webpackChunkName: "catalogousuario" */ "./Catalogos/Usuario.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/brandings",
    name: "CatalogoBrandings",
    component: () =>
      import(
        /* webpackChunkName: "catalogobrandings" */ "./Catalogos/Brandings.vue"
      ),
  },
  {
    path: "/cmms/catalogos/brandings/:id",
    name: "CatalogoBranding",
    component: () =>
      import(
        /* webpackChunkName: "catalogobranding" */ "./Catalogos/Branding.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/clientes",
    name: "CatalogoClientes",
    component: () =>
      import(
        /* webpackChunkName: "catalogoclientes" */ "./Catalogos/Clientes.vue"
      ),
  },
  {
    path: "/cmms/catalogos/clientes/:id",
    name: "CatalogoCliente",
    component: () =>
      import(
        /* webpackChunkName: "catalogocliente" */ "./Catalogos/Cliente.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/equipos",
    name: "CatalogoEquipos",
    component: () =>
      import(
        /* webpackChunkName: "catalogoequipos" */ "./Catalogos/Equipos.vue"
      ),
  },
  {
    path: "/cmms/catalogos/equipos/:id",
    name: "CatalogoEquipo",
    component: () =>
      import(/* webpackChunkName: "catalogoequipo" */ "./Catalogos/Equipo.vue"),
    props: true,
  },
  {
    path: "/cmms/catalogos/tipoordenservicio",
    name: "CatalogoTipoOrdenServicio",
    component: () =>
      import(
        /* webpackChunkName: "catalogotipoordenservicio" */ "./Catalogos/TiposOrdenesServicio.vue"
      ),
  },
  {
    path: "/cmms/catalogos/insumos",
    name: "CatalogoInsumosLista",
    component: () =>
      import(
        /* webpackChunkName: "catalogoinsumoslista" */ "./Catalogos/Insumos.vue"
      ),
  },
  {
    path: "/cmms/catalogos/insumos/:id",
    name: "CatalogoInsumoId",
    component: () =>
      import(
        /* webpackChunkName: "catalogoinsumoid" */ "./Catalogos/Insumo.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/kitinsumos",
    name: "CatalogoKitInsumos",
    component: () =>
      import(
        /* webpackChunkName: "catalogokitinsumos" */ "./Catalogos/Kitinsumos.vue"
      ),
  },
  {
    path: "/cmms/catalogos/kitinsumos/:id",
    name: "CatalogoKitInsumo",
    component: () =>
      import(
        /* webpackChunkName: "catalogokitinsumo" */ "./Catalogos/Kitinsumo.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/checklists",
    name: "CatalogoChecklists",
    component: () =>
      import(
        /* webpackChunkName: "catalogochecklists" */ "./Catalogos/Checklists.vue"
      ),
  },
  {
    path: "/cmms/catalogos/checklists/:id",
    name: "catalogochecklist",
    component: () =>
      import(
        /* webpackChunkName: "catalogochecklist" */ "./Catalogos/Checklist.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos/tiposequipos",
    name: "TiposEquipos",
    component: () =>
      import(
        /* webpackChunkName: "TiposEquipos" */ "./Catalogos/Tiposequipos.vue"
      ),
  },
  {
    path: "/cmms/catalogos/tiposequipos/:id",
    name: "Tipoequipo",
    component: () =>
      import(/* webpackChunkName: "Tipoequipo" */ "./Catalogos/Tipoequipo.vue"),
    props: true,
  },
  {
    path: "/cmms/catalogos/almacenes",
    name: "Almacenes",
    component: () =>
      import(/* webpackChunkName: "almacenes" */ "./Catalogos/Almacenes.vue"),
  },
  {
    path: "/cmms/catalogos/almacenes/:id",
    name: "Almacen",
    component: () =>
      import(/* webpackChunkName: "almacen" */ "./Catalogos/Almacen.vue"),
    props: true,
  },
  {
    path: "/cmms/catalogos/sucursales",
    name: "SucursalesLista",
    component: () =>
      import(
        /* webpackChunkName: "sucursaleslista" */ "./Catalogos/Sucursales.vue"
      ),
  },
  {
    path: "/cmms/catalogos/sucursales/:id",
    name: "SucursalHandler",
    component: () =>
      import(/* webpackChunkName: "sucursalid" */ "./Catalogos/Sucursal.vue"),
    props: true,
  },
  {
    path: "/cmms/catalogos/areas",
    name: "AreasLista",
    component: () =>
      import(/* webpackChunkName: "areaslista" */ "./Catalogos/Areas.vue"),
  },
  {
    path: "/cmms/catalogos/areas/:id",
    name: "AreaHandler",
    component: () =>
      import(/* webpackChunkName: "areahandler" */ "./Catalogos/Area.vue"),
    props: true,
  },
  {
    path: "/cmms/catalogos/importar",
    name: "CatalogoImportar",
    component: () =>
      import(
        /* webpackChunkName: "catalogoimportar" */ "./Catalogos/Importar.vue"
      ),
  },
  {
    path: "/cmms/reportes",
    name: "CMMSReportes",
    component: () =>
      import(/* webpackChunkName: "cmmsreportes" */ "./Reportes/Reportes.vue"),
  },
  {
    path: "/cmms/convenios",
    name: "CMMSConvenios",
    component: () =>
      import(
        /* webpackChunkName: "cmmsconvenios" */ "./Convenios/Convenios.vue"
      ),
  },
  {
    path: "/cmms/convenios/:id",
    name: "CMMSConvenioHandler",
    component: () =>
      import(
        /* webpackChunkName: "cmmsconveniohandler" */ "./Convenios/Convenio.vue"
      ),
    props: true,
  },
  {
    path: "/cmms/catalogos",
    name: "CMMSCatalogosMenu",
    component: () =>
      import(/* webpackChunkName: "cmmscatalogosmenu" */ "./Menu/Menu.vue"),
  },
  {
    path: "/cmms/fotos",
    name: "CMMSFotos",
    component: () =>
      import(/* webpackChunkName: "cmmsfotos" */ "./FotosLista.vue"),
  },
];
const e = Api.fetchLocal("empresadb") || {};
const manifiesto = {
  nombre: "CMMS",
  descripcion: "Actividades de mantenimiento",
  //icono: "hammer",
  icono: "cmms",
  tarifaDefault: 2499,
  permisosPosibles: [
    "Puede crear órdenes",
    "Puede eliminar órdenes",
    "Puede ver órdenes de otros usuarios",
    "Puede agendar citas",
    "Puede gestionar catálogos",
    "Puede agregar insumos desde ordenes",
    "Puede crear inventarios",
    "Puede hacer mediciones rápidas",
  ],
  rutaPrincipal: "/cmms",
  menuArbol: [
    {
      ruta: "/cmms/ordenes",
      label: "Órdenes de servicio",
      permiso: "Puede crear órdenes",
      icono: "hammerOutline",
    },
    /*{
      ruta: "/cmms/mediciones",
      label: "Mediciones rápidas",
      permiso: "Puede hacer mediciones rápidas",
      icono: "flashOutline",
    }, //*/
    {
      ruta: "/cmms/agenda",
      label: "Agenda",
      permiso: "Puede agendar citas",
      icono: "calendarOutline",
    },
    {
      ruta: "/cmms/fotos",
      label: "Biblioteca de fotos",
      permiso: "Puede crear órdenes",
      icono: "imagesOutline",
    },
    {
      ruta: "/cmms",
      label: "",
      permiso: "",
      icono: "listOutline",
    },
    {
      ruta: "/cmms/convenios",
      label: "Convenios presupuestales",
      permiso: "Puede gestionar catálogos",
      icono: "wallet",
      ocultar: () => !e.variables.conveniosPresupuestales,
    },
    {
      ruta: "/cmms/reportes",
      label: "Reportes",
      permiso: "Puede ver reportes",
      icono: "statsChartOutline",
    },
    {
      ruta: "/cmms/catalogos",
      label: "Catàlogos",
      permiso: "Puede gestionar catálogos",
      icono: "cubeOutline",
    },
  ],
  variables: [
    {
      label: "Usar mediciones rápidas",
      name: "medicionesRapidasr",
      type: "binary",
    },
    {
      label: "Usar convenios presupuestales",
      name: "conveniosPresupuestales",
      type: "binary",
    },
    {
      label: "Usar sucursales",
      name: "usarSucursales",
      type: "binary",
    },
    {
      label: "Usar áreas",
      name: "usarAreas",
      type: "binary",
    },
  ],
};

let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

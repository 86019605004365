<template>
  <ion-menu content-id="main-content" type="overlay">
    <ion-content v-if="yo?._id">
      <ion-list>
        <AsyncImg
          :src="empresa.logo"
          css="menulogo ion-padding-vertical dark:bg-slate-500 dark:rounded-lg dark:mx-4 dark:my-2"
        />
        <!--ion-img src="ittoni/SVG/icono.svg" v-else class="menulogo"></ion-img-->
        <ion-item lines="none" detail="false">
          <ion-label @click="go('/')" text-wrap>
            <h2>{{ yo.nombre || "Invitado" }}</h2>
            <p class="capitalize">
              {{ yo.rol ?? "" }} en
              {{ empresa.nombre ?? "Tu empresa" }}
            </p>
          </ion-label>
          <ion-button
            slot="end"
            fill="clear"
            color="medium"
            size="small"
            @click="go('/config')"
            v-if="yo.rol == 'administrador'"
          >
            <IonIcon :icon="cogOutline" />
          </ion-button>
          <ion-button slot="end" fill="clear" @click="salir" size="small">
            <ion-icon :icon="logOutOutline"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-menu-toggle auto-hide="false" v-if="!yo._id">
          <ion-item router-link="/login">
            <ion-icon slot="start" :icon="logIn"></ion-icon>
            <ion-label>Iniciar sesión</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-item v-if="yo.rol == 'administrador'" color="light">
        <ion-icon slot="end" :icon="lockOpenOutline" />
        <ion-label>
          <h1>{{ tokenHash }}</h1>
          <p>Token cambia cada media hora</p>
        </ion-label>
      </ion-item>
      <IonItem v-if="usuarios.length > 0">
        <IonSelect
          v-model="idUsuario"
          label="Ver como usuario"
          label-placement="floating"
        >
          <IonSelectOption v-for="u in usuarios" :key="u._id" :value="u._id"
            >{{ u.nombre ?? u.nombreLargo ?? u.email }}
            {{ u.rol }}</IonSelectOption
          >
        </IonSelect>
        <IonButton v-if="idUsuario != yo._id" @click="idUsuario = yo._id">
          <IonIcon :icon="reload" />
        </IonButton>
      </IonItem>
      <p>&nbsp;</p>
      <IonItem>
        <IonLabel><p>Mis productos</p></IonLabel>
      </IonItem>
      <IonList
        v-for="(manifiesto, nombreModulo) in menuArbol"
        :key="nombreModulo"
      >
        <ion-item
          lines="none"
          @click="toggleMostrar(nombreModulo, false, manifiesto)"
        >
          <ion-icon
            slot="end"
            v-if="iconosDescargados"
            :icon="iconos(manifiesto.icono)"
            :color="mostrar[nombreModulo] ? '' : 'medium'"
          />
          <ion-label
            text-wrap
            :color="mostrar[nombreModulo] ? 'primary' : 'medium'"
          >
            <h1>{{ manifiesto.nombre }}</h1>
            <p>{{ manifiesto.descripcion }}</p>
          </ion-label>
        </ion-item>
        <Transition>
          <div v-if="mostrar[nombreModulo]">
            <ion-menu-toggle
              auto-hide="false"
              v-for="l in manifiesto.menuArbol
                .filter((u) => !u.ocultar)
                .filter((u) => permiso(yo, u.permiso))"
              :key="l.label"
            >
              <ion-item
                :router-link="l.ruta"
                :button="true"
                detail="false"
                :lines="l.label != '' ? 'solid' : 'none'"
              >
                <IonIcon
                  :icon="iconos(l.icono)"
                  color="medium"
                  slot="end"
                  size="small"
                  v-if="l.label != '' && iconosDescargados"
                ></IonIcon>
                <ion-label text-wrap>{{ l.label }} </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <p>&nbsp;</p>
          </div>
        </Transition>
      </IonList>
    </ion-content>
  </ion-menu>
</template>
<style>
ion-menu,
.menu-pane-visible {
  --width: 300px;
}
.menulogo {
  padding-left: 35%;
  padding-right: 35%;
  padding-bottom: 5%;
}

.subicono {
  position: relative;
  display: block;
  float: left;
  max-width: 36px;
  max-height: 36px;
  top: 55px;
  left: 40vw;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
<script setup>
import Api from "@/common/Api/Api";
const arbol = Api.fetchLocal("menuArbol") ?? {};
import AsyncImg from "@/common/AsyncImg.vue";
let yo = Api.fetchLocal("yo") ?? {};
const empresa = Api.fetchLocal("empresadb") ?? {};
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonButton,
  menuController,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import {
  cogOutline,
  logIn,
  logOutOutline,
  lockOpenOutline,
  reload,
} from "ionicons/icons";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
const mostrar = ref({});
const iconosIonic = ref({});
const iconosDescargados = ref(false);
const usuarios = ref([]);
const idUsuario = ref(yo._id);
const usuario = computed(() => {
  let usr = yo;
  for (const u of usuarios.value) if (u._id == idUsuario.value) usr = u;
  return usr;
});
const tokenHash = computed(() => {
  return Api.tokenHash();
});
const menuArbolOriginal = computed(() => {
  return arbol;
});
onMounted(async () => {
  iconosIonic.value = await import("ionicons/icons");
  if (yo.rol == "administrador")
    usuarios.value = (await Api.get("directorio"))
      .filter((u) => u.email)
      .filter((u) => ["proveedor", "cliente"].indexOf(u.rol) < 0)
      .sort((a, b) =>
        (a.nombre ?? a.nombreLargo ?? a.email ?? "").localeCompare(
          b.nombre ?? a.nombreLargo ?? b.email ?? ""
        )
      );
  iconosDescargados.value = true;
  setInterval(comprobarSesion, 1500);
});
const menuArbol = computed(() => {
  let mA = {};
  let nombreModulo = Api.fetchLocal("menuMostrarModulo") ?? "";
  let modulosQuePuedeVerEsteUsuario = [];
  switch (usuario.value.rol) {
    case "proveedor":
      mA = {};
      for (const producto in arbol) {
        if (arbol[producto].menuRol && arbol[producto].menuRol.proveedor)
          mA[producto] = {
            ...arbol[producto],
            menuArbol: arbol[producto].menuRol.proveedor,
          };
      }
      console.log("Arbol de proveedor", menuArbol.value);
      break;
    default:
      for (let modulo in usuario.value.permisos ?? {})
        if (
          usuario.value.permisos[modulo] &&
          usuario.value.permisos[modulo][modulo] == true
        )
          modulosQuePuedeVerEsteUsuario.push(modulo);
      for (let nombreModulo in menuArbolOriginal.value)
        if (
          (empresa.productos || {})[nombreModulo] &&
          (usuario.value.rol == "administrador" ||
            modulosQuePuedeVerEsteUsuario.indexOf(nombreModulo) >= 0)
        )
          mA[nombreModulo] = menuArbolOriginal.value[nombreModulo];
  }
  toggleMostrar(nombreModulo);
  return mA;
});
const router = useRouter();
const go = (str) => {
  router.push(str);
  menuController.close();
};
const permiso = (str = "") => {
  if (yo.rol == "proveedor")
    // tiene su propio menu
    return true;
  return Api.permiso(str);
};
const iconos = (str = "") => {
  if (Object.keys(iconosIonic.value).length > 0)
    return iconosIonic.value[str] || "ittoni/SVG/" + str + ".svg";
  return "ittoni/SVG/93f3b3.svg";
};
const toggleMostrar = (nombreModulo = "", forzar = false, manifiesto = {}) => {
  Api.saveLocal("menuMostrarModulo", nombreModulo);
  for (let i in mostrar.value) mostrar.value[i] = false;
  mostrar.value[nombreModulo] = true || forzar;
  if (manifiesto.rutaPrincipal) router.push(manifiesto.rutaPrincipal);
};
const comprobarSesion = () => {
  yo = Api.fetchLocal("yo") ?? {};
};
const salir = () => {
  Api.logout();
};
</script>

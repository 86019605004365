let rutaLocal = "@/apps/FilePoker/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/filepoker",
    name: "FilePoker",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "FilePoker"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "File poker",
  // descripcion aparece en el menú
  descripcion: "Maquina del tiempo de archivos",
  tarifaDefault: 1999,
  // Ícono principal
  icono: "fileTrayFull",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: ["Puede consultar archivos de la máquina del tiempo"],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/filepoker",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/filepoker",
      label: "File poker",
      permiso: "Puede consultar archivos de la máquina del tiempo",
      icono: "fileTrayFullOutline",
    },
  ],
  variables: [
    {
      label: "URL de descarga del software de rastreo de archivos",
      name: "rutaSoftwareFilePoker",
      type: "url",
      required: true,
      detail: false,
    },
  ],
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

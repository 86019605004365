let rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/posts",
    name: "Posts del blog",
    component: () =>
      import(/* webpackChunkName: "blogpostslista" */ rutaLocal + "Lista"),
  },
  {
    path: "/posts/:id",
    name: "Post del blog",
    component: () =>
      import(/* webpackChunkName: "blogpostid" */ rutaLocal + "Post"),
    props: true,
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Blog",
  // descripcion aparece en el menú
  descripcion: "Blog de la app",
  tarifa: 0,
  // Ícono principal
  icono: "create",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: ["Puede crear posts", "Puede eliminar posts"],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/posts",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/posts",
      label: "Posts del blog",
      permiso: "Puede crear posts",
      icono: "create",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

<script>
//const sha1 = require("sha1");
import { v4 as uuidv4 } from "uuid";
const prefijo = "ittoniapp3";
const modoProduccion = window.location.href.indexOf("localhost") < 0;
const defaultTimeout = 1000;
const metodos = {
  uuid: function () {
    return uuidv4().replace(/-/g, "").substring(0, 24);
  },
  login() {
    window.alert("Implementando");
  },
  wait: function (time = defaultTimeout) {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("wait " + time);
        resolve(true);
      }, time);
    });
  },
  //
  saveLocal: async function (lugar = "", obj) {
    if (typeof obj == "object")
      localStorage.setItem(prefijo + "." + lugar, JSON.stringify(obj));
    else localStorage.setItem(prefijo + "." + lugar, obj);
    return true;
  },
  fetchLocal: function (lugar = "") {
    lugar = prefijo + "." + lugar;
    let r = null;
    try {
      r = JSON.parse(localStorage.getItem(lugar));
    } catch (e) {
      r = localStorage.getItem(lugar);
    }
    return r;
  },
  deleteLocal: function (lugar = "") {
    console.log("deleteLocal", lugar);
    localStorage.removeItem(prefijo + "." + lugar);
    return true;
  },
};
export default {
  ...metodos,
  modoProduccion,
  prefijo,
};
</script>

let rutaLocal = "@/apps/Timing/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/timing",
    name: "Timing",
    component: () => import(rutaLocal + "Timing"),
  },
  {
    path: "/timing/actividades",
    name: "TimingActividades",
    component: () => import(rutaLocal + "Praxis/Actividades"),
  },
  {
    path: "/timing/actividades/:id",
    name: "TimingActivdadesID",
    component: () => import(rutaLocal + "Praxis/ActividadHandler"),
    props: true,
  },
  {
    path: "/timing/asuntos",
    name: "TimingAsuntos",
    component: () => import(rutaLocal + "Praxis/Asuntos"),
  },
  {
    path: "/timing/asuntos/:id",
    name: "TimingAsuntosID",
    component: () => import(rutaLocal + "Praxis/Asunto"),
    props: true,
  },
  {
    path: "/timing/asuntos/:id/:modo2",
    name: "TimingAsuntosIDModo",
    component: () => import(rutaLocal + "Praxis/Asunto"),
    props: true,
  },
  {
    path: "/timing/reportes",
    name: "TimingReportes",
    component: () => import(rutaLocal + "Reportes/Reportes"),
    props: true,
  },
  {
    path: "/timing/timingdashboard",
    name: "TimingReportesDashboard",
    component: () => import(rutaLocal + "Reportes/Dashboard"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Timekeeping",
  // descripcion aparece en el menú
  descripcion: "Gestión de tiempo laborado especializado en abogados",
  tarifa: 4999,
  // Ícono principal
  //icono: "briefcase",
  icono: "timing",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear asuntos",
    "Puede crear actividades",
    "Puede administrar cobranza",
    "Puede ver reportes de operación",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/timing",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/timing",
      label: "Praxis",
      permiso: "Puede crear asuntos",
      icono: "timerOutline",
    },
    {
      ruta: "/timing/actividades/0",
      label: "Capturar actividades",
      permiso: "Puede crear actividades",
      icono: "createOutline",
    },
    {
      ruta: "/timing/timingdashboard",
      label: "Dashboard",
      permiso: "Puede ver reportes de operación",
      icono: "speedometerOutline",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
  variables: [
    {
      label: "Nombre del despacho",
      type: "text",
      name: "nombreDespacho",
    },
    {
      label: "Logo",
      name: "uploadimagen",
    },
    {
      label: "Sucursal",
      name: "sucursal",
      type: Boolean,
    },
  ],
  propsUsuario: [
    {
      label: "Tipo de abogado",
      name: "tipo",
      type: "select",
      options: ["director", "asociado", "socio", "administrativo", "pasante"],
      required: true,
    },
    {
      label: "Tarifa estándar",
      name: "tarifa",
      type: "number",
      required: true,
    },
  ],
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

let rutaLocal = "@/apps/RH/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/rh",
    name: "RHMenu",
    component: () =>
      import(/* webpackChunkName: "rhmenu" */ rutaLocal + "Menu"),
  },
  {
    path: "/rh/actividad",
    name: "RHActividad",
    component: () =>
      import(/* webpackChunkName: "rhactividad" */ rutaLocal + "MiActividad"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "RH",
  // descripcion aparece en el menú
  descripcion: "Recursos Humanos",
  tarifa: 0,
  // Ícono principal
  icono: "peopleCircle",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear empleados",
    "Puede eliminar empleados",
    "Puede ver reportes",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/rh",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/rh",
      label: "Recursos Humanos",
      permiso: "Puede crear empleados",
      icono: "peopleCircle",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

import firebase from "firebase/app";
import "firebase/messaging";

const fcmService = {
  endpoint: "",
  messaging: null,

  inicializar: function (str = "", firebaseConfig = {}) {
    this.endpoint = str;

    // Initialize Firebase if not already initialized
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      console.log("Firebase initialized");
    }

    // Initialize Firebase Cloud Messaging
    this.messaging = firebase.messaging();
    this.messaging.onMessage((payload) => {
      console.log("Message received. ", payload);
      // Customize notification here, or handle incoming messages
    });
  },

  // Function to get FCM token
  obtenerToken: async function () {
    try {
      if (!this.messaging) {
        throw new Error("Firebase must be initialized first.");
      }

      const token = await this.messaging.getToken();
      console.log("FCM Token:", token);
      return token;
    } catch (error) {
      console.error("Error getting FCM token:", error);
      return null;
    }
  },

  // Function to send standard notifications
  sendNotification: async function (tokens, title, body, image) {
    try {
      if (this.endpoint == "")
        throw new Error(
          "FCM debe ser inicializado con la URL del servidor proxy"
        );
      const response = await fetch(this.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokens: tokens,
          title: title,
          body: body,
          image: image,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "Failed to send notification");
      }

      console.log("Notification sent successfully:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  },

  // Function to send data-only messages
  sendDataOnlyMessage: async function (tokens, data) {
    try {
      if (this.endpoint == "")
        throw new Error(
          "FCM debe ser inicializado con la URL del servidor proxy"
        );
      const response = await fetch(this.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokens: tokens,
          data: data,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "Failed to send data-only message");
      }

      console.log("Data-only message sent successfully:", result);
    } catch (error) {
      console.error("Error sending data-only message:", error);
    }
  },
};

export default fcmService;

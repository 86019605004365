const rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/mallainsa/dashboard",
    name: "MallainsaDashboard",
    component: () =>
      import(
        /* webpackChunkName: "mallainsadashboard" */ rutaLocal + "ERP/Dashboard.vue"
      ),
  },
  {
    path: "/mallainsa/erp/:modooperacion",
    props: true,
    name: "MallainsaERP",
    component: () =>
      import(
        /* webpackChunkName: "mallainsaerplista" */ rutaLocal + "ERP/Lista.vue"
      ),
  },
  {
    path: "/mallainsa/erp/:modooperacion/:id",
    name: "MallainsaProyecto",
    component: () =>
      import(
        /* webpackChunkName: "mallainsaerphandler" */ rutaLocal +
          "ERP/Handler.vue"
      ),
    props: true,
  },
];
const manifiestos = {
  "MALLAINSA Dashboard": {
    nombre: "Dashboard",
    descripcion: "Dashboard operativo",
    tarifa: 0,
    icono: "mallainsa2",
    permisosPosibles: [
      "Puede crear tareas",
      "Puede eliminar tareas",
      "Puede ver tareas de otros usuarios",
    ],
    rutaPrincipal: "/mallainsa/dashboard",
    menuArbol: [
      {
        ruta: "/mallainsa/dashboard",
        label: "Dashboard",
        permiso: "Puede crea tareas",
        icono: "mallainsa2",
      },
      
    ],
    variables: [
      {
        label: "Utilizar componentes",
        name: "utilizarComponentes",
        type: "binary",
      },
      {
        label: "Utilizar variantes",
        name: "utilizarVariantes",
        type: "binary",
      },
    ],
  },
  "MALLAINSA ERP": {
    nombre: "Ventas",
    descripcion: "Ventas y seguimiento de clientes",
    tarifa: 0,
    icono: "mallainsa2",
    permisosPosibles: [
      "Puede crear tareas",
      "Puede eliminar tareas",
      "Puede ver tareas de otros usuarios",
    ],
    rutaPrincipal: "/mallainsa/erp/ventas",
    menuArbol: [
      {
        ruta: "/mallainsa/erp/ventas",
        label: "ERP",
        permiso: "Puede crea tareas",
        icono: "shieldOutline",
      },
      {
        ruta: "/erp/catalogos",
        label: "Catálogos",
        permiso: "Puede gestionar catalogos",
        icono: "listCircleOutline",
      },
      {
        ruta: "/erp/reportes",
        label: "Reportes",
        permiso: "Puede ver tareas de otros usuarios",
        icono: "statsChartOutline",
      },
    ],
    variables: [
      {
        label: "Utilizar componentes",
        name: "utilizarComponentes",
        type: "binary",
      },
      {
        label: "Utilizar variantes",
        name: "utilizarVariantes",
        type: "binary",
      },
    ],
  },
  "MALLAINSA Produccion": {
    nombre: "Producción",
    descripcion: "Seguimiento en campo",
    tarifa: 0,
    icono: "build",
    permisosPosibles: [
      "Puede crear tareas",
      "Puede eliminar tareas",
      "Puede ver tareas de otros usuarios",
    ],
    rutaPrincipal: "/mallainsa/erp/produccion",
    menuArbol: [
      {
        ruta: "/mallainsa/erp/produccion",
        label: "Producción",
        permiso: "Puede crea tareas",
        icono: "shieldOutline",
      },
    ],
    variables: [
      {
        label: "Utilizar componentes",
        name: "utilizarComponentes",
        type: "binary",
      },
      {
        label: "Utilizar variantes",
        name: "utilizarVariantes",
        type: "binary",
      },
    ],
  },
  "MALLAINSA Contabilidad": {
    nombre: "Contabilidad",
    descripcion: "Seguimiento de cobranza",
    tarifa: 0,
    icono: "wallet",
    permisosPosibles: [
      "Puede crear tareas",
      "Puede eliminar tareas",
      "Puede ver tareas de otros usuarios",
    ],
    rutaPrincipal: "/mallainsa/erp/contabilidad",
    menuArbol: [
      {
        ruta: "/mallainsa/erp/contabilidad",
        label: "Cobranza",
        permiso: "Puede crea tareas",
        icono: "shield",
      },
    ],
    variables: [
      {
        label: "Utilizar componentes",
        name: "utilizarComponentes",
        type: "binary",
      },
      {
        label: "Utilizar variantes",
        name: "utilizarVariantes",
        type: "binary",
      },
    ],
  },
  "MALLAINSA Almacen": {
    nombre: "Almacen",
    descripcion: "Almacen y logistica",
    tarifa: 0,
    icono: "fileTrayFull",
    permisosPosibles: [
      "Puede crear tareas",
      "Puede eliminar tareas",
      "Puede ver tareas de otros usuarios",
    ],
    rutaPrincipal: "/mallainsa/erp/almacen",
    menuArbol: [
      {
        ruta: "/mallainsa/erp/almacen",
        label: "Proyectos y ordenes de salida",
        permiso: "Puede crea tareas",
        icono: "shieldOutline",
      },
      {
        ruta: "/almacen",
        label: "Gestión completa de almacén",
        icono: "fileTrayFull",
      },
    ],
    variables: [
      {
        label: "Utilizar componentes",
        name: "utilizarComponentes",
        type: "binary",
      },
      {
        label: "Utilizar variantes",
        name: "utilizarVariantes",
        type: "binary",
      },
    ],
  },
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol = { ...menuArbol, ...manifiestos };
Api.saveLocal("menuArbol", menuArbol);
export default routes;

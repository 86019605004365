<template>
  <img :src="finalUrl" :class="css" v-if="tipo == 'img' && finalUrl != ''" />
  <IonImg
    :src="finalUrl"
    :class="css"
    v-if="tipo.startsWith('ion') && finalUrl != ''"
    @ionError="defaultImg()"
  />
</template>
<script>
import Api from "@/common/Api/Api";
import { IonImg } from "@ionic/vue";
export default {
  name: "AsyncImg",
  components: { IonImg },
  props: {
    src: {
      type: String,
      default: "",
    },
    tipo: {
      type: String,
      default: "ionic",
    },
    css: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.obtener();
  },
  data() {
    return { finalUrl: "" };
  },
  watch: {
    src: function () {
      this.obtener();
    },
  },
  methods: {
    async obtener() {
      if (typeof Api.urlizeAsync == "function") {
        this.finalUrl = await Api.urlizeAsync(this.src);
      } else this.finalUrl = Api.urlize(this.src);
    },
    defaultImg() {
      const logoApp = "haakon.svg";
      const empresa = Api.fetchLocal("empresadb") ?? {};
      this.finalUrl = Api.urlize(empresa.logo ?? logoApp);
    },
  },
};
</script>

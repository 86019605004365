<script>
import ApiWst from "./ApiWst.vue";
import ApiGoogle from "./ApiGoogle.vue";
import ApiPouch from "./ApiPouch.vue";
import FcmFunctions from "./FcmFunctions.js";
const modo = ApiWst.fetchLocal("modoAPI") ?? "wst";
const APIs = {
  wst: ApiWst,
  google: ApiGoogle,
  local: ApiPouch,
};
console.log({ modo });
import Permisos from "./Permisos.vue";
import HelperFunctions from "./HelperFunctions";
const yo = ApiWst.fetchLocal("yo") || {};
HelperFunctions.sujeto = yo;
const exportable = {
  ...(APIs[modo] ?? ApiWst),
  ...Permisos,
  ...HelperFunctions,
  ...FcmFunctions,
};
export default exportable;
</script>

<script>
import sha1 from "sha1";
import axios from "axios";
import PouchDB from "pouchdb";
const db = new PouchDB("ittoni.app3", { auto_compaction: true, revs_limit: 1 });
const appKey = "ittoni.app3.pouch";
import { v4 as uuidv4 } from "uuid";
const prefijo = "ittoniapp3";
const modoProduccion = window.location.href.indexOf("localhost") < 0;
const defaultTimeout = 1000;
const cdn = "https://app.haakon.cc:8083/files";
(async () => {
    console.log("API POUCH", await db.info());
})();
const metodosBase = {
    uuid: () => uuidv4().replace(/-/g, "").substring(0, 24),
    token: () => uuidv4().replace(/-/g, "").substring(0, 24),
    login: async (usuario = "admin@local", password = "") => {
        const usr = await metodosCRUD.find("directorio", [
            "email,eq," + usuario,
            //"password,eq," + sha1(password),
        ]);
        console.log("C?", usr);
        if (usr[0]) {
            console.log("CANDIDATO", usr[0].nombre);
            if (usr[0].password == sha1(password)) {
                return { ...usr[0], jwt: metodosBase.uuid() };
            } else return Promise.reject("Credenciales incorrectas");
        } else if (usr.length == 0) {
            password = await window.promptAsync(
                "Confirme su contraseña para guardar en su dispositivo por primera vez",
                "password",
            );
            password = sha1(password);
            const usuarioNuevo = await metodosCRUD.save("directorio", {
                nombre: "Admin",
                rol: "administrador",
                password: password,
                email: "admin@local",
            });
            return usuarioNuevo;
        }
    },
    logout: async function () {
        metodosBase.deleteLocal("yo");
        metodosBase.deleteLocal("empresadb");
        metodosBase.deleteLocal("menuArbol");
        // borrar los datos locales
        for (let key in localStorage) {
            if (key.split("_").length == 2) {
                localStorage.removeItem(key);
                // console.log("Eliminado", key);
            }
        }
        //
        try {
            window.toast("Sesión cerrada");
            //let empresastr = metodos.fetchLocal("empresastr");
            let rutaLogin = metodosBase.fetchLocal("rutaLogin") || "/login";
            console.log("rutaLogin?", rutaLogin);
            //if (empresastr && empresastr != "") rutaLogin += "/" + empresastr;
            rutaLogin = "#" + rutaLogin;
            window.location.assign(rutaLogin);
            await metodosBase.wait(2000);
        } catch (e) {
            console.error(e);
        }
        window.location.reload();
    },
    varGlobal(str = "", valorDefault = "") {
        let variable = valorDefault;
        const empresa = metodosBase.fetchLocal("empresadb") || {};
        //console.log("varglobal", str, valorDefault, empresa.variables);
        if (
            empresa &&
            empresa.variables &&
            empresa.variables[str] &&
            empresa.variables[str] != ""
        )
            variable = empresa.variables[str];
        return variable;
    },
    wait: function (time = defaultTimeout) {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log("wait " + time);
                resolve(true);
            }, time);
        });
    },
    urlize: (str = "") => {
        if (str.startsWith("ul_")) return metodosBase.urlizeAsync(str);
        return str.startsWith("data") || str.startsWith("http")
            ? str
            : cdn + "/" + encodeURIComponent(str);
    }, //*/
    urlizeAsync: async (str = "") => {
        if (str.startsWith("data") || str.startsWith("http")) {
            // If it's a data URL or an external URL, return it as is
            return str;
        } else if (str.startsWith("ul_")) {
            try {
                const obj = await metodosCRUD.get("ul", str);
                const file = obj._attachments.file;
                const url = `data:${file.content_type};base64,${file.data}`;
                console.log("URLIZE pouch", url.substring(0, 50));
                return url;
            } catch (e) {
                console.error("EURLIZE", e);
            }
        }
        return cdn + "/" + str;
    },
    //
    saveLocal: async function (lugar = "", obj) {
        if (typeof obj == "object")
            localStorage.setItem(prefijo + "." + lugar, JSON.stringify(obj));
        else localStorage.setItem(prefijo + "." + lugar, obj);
        return true;
    },
    fetchLocal: function (lugar = "") {
        lugar = prefijo + "." + lugar;
        let r = null;
        try {
            r = JSON.parse(localStorage.getItem(lugar));
        } catch (e) {
            r = localStorage.getItem(lugar);
        }
        return r;
    },
    deleteLocal: function (lugar = "") {
        console.log("deleteLocal", lugar);
        localStorage.removeItem(prefijo + "." + lugar);
        return true;
    },
};
const metodosCRUD = {
    get: async (modelo = "", id = "") => {
        // TODO implementar anularTimeout y subset
        try {
            if (id !== "") {
                // If ID is specified, get the specific object from PouchDB
                const idReal = id.startsWith(modelo)
                    ? id
                    : [modelo, id].join("_");
                console.log("GET?", modelo, idReal);
                const result = await db.get(idReal, {
                    attachments: modelo.startsWith("ul"),
                });
                console.log("GET", result);
                return result;
            } else {
                // If ID is not specified, return every object with ID starting with "modelo"
                const prefix = modelo + "_";
                const result = await db.allDocs({
                    startkey: prefix,
                    endkey: prefix + "\uffff",
                    include_docs: true,
                });
                const objects = result.rows.map((row) => row.doc);
                return objects.filter((u) => u._id);
            }
        } catch (error) {
            console.error("EAPIGET " + modelo, error);
            return Promise.reject(error);
        }
    },
    find: function (modelo = "", params = [], adicional = {}) {
        // TODO implementar anularTimeout y subset
        console.log("FIND?", modelo, params);
        return new Promise(async (resolve) => {
            // If ID is specified, get the specific object from PouchDB
            const prefix = modelo + "_";
            const result = await db.allDocs({
                startkey: prefix,
                endkey: prefix + "\uffff",
                include_docs: true,
            });

            let objects = result.rows.map((row) => row.doc);
            objects = objects
                .filter((o) => o._id)
                .filter((o) => {
                    //console.log("O", modelo, o);
                    let candidato = 0;
                    for (const filtro of params) {
                        let [campo, comparador, valor] = filtro.split(",");
                        if (!isNaN(valor)) valor = parseFloat(valor);
                        switch (comparador) {
                            case "cs":
                            case "contains":
                                candidato += o[campo].indexOf(valor) >= 0;
                                break;
                            case "ncs":
                                candidato += o[campo].indexOf(valor) < 0;
                                break;
                            case "gt":
                                candidato += o[campo] > valor;
                                break;
                            case "gte":
                                candidato += o[campo] >= valor;
                                break;
                            case "lt":
                                candidato += o[campo] < valor;
                                break;
                            case "lte":
                                candidato += o[campo] <= valor;
                                break;
                            case "ne":
                                candidato += o[campo] != valor;
                                break;
                            case "eq":
                            default:
                                candidato += o[campo] == valor;
                        }
                    }
                    //console.log("O?", o._id, candidato, params.length);
                    return candidato == params.length;
                }); //*/
            if (adicional.limit) objects = objects.split(0, adicional.limit);
            if (adicional.subset) {
                for (const id in objects) {
                    for (const s of adicional.subset) {
                        console.log("Subset", s.alias);
                        let subcoleccion = await metodosCRUD.find(s.coleccion, [
                            s.query + objects[id][s.sustituir],
                        ]);
                        objects[id][s.alias] =
                            s.unico == true ? subcoleccion[0] : subcoleccion;
                    }
                }
            }
            if (adicional.sort) {
                if (typeof adicional.sort == "string")
                    adicional.sort = [adicional.sort];
                for (const criterio of adicional.sort) {
                    let [campo, orden] = criterio.split(",");
                    orden = parseInt(orden);
                    objects = objects.sort((a, b) =>
                        a[campo] > b[campo] ? orden : -orden,
                    );
                }
            }
            console.log("FIND", modelo, params, adicional, objects.length);
            resolve(objects);
        });
    },
    save: async (modelo = "", objeto = {}) => {
        if (!objeto._id) objeto._id = [modelo, metodosBase.uuid()].join("_");
        if (!objeto._id.startsWith(modelo))
            objeto._id = [modelo, objeto._id].join("_");
        console.log("SAVE", modelo, objeto._id);
        try {
            const s = await db.put(objeto);
            console.log("SAVE?", modelo, objeto._id, s);
            return objeto;
        } catch (e) {
            console.error("ESAVE", objeto._id, e.name);
            if (e.name == "conflict") {
                let objExistente = await metodosCRUD.get(modelo, objeto._id);
                objExistente = { ...objExistente, ...objeto };
                return metodosCRUD.save(modelo, objExistente);
            }
            return {};
        }
    },
    delete: (modelo = "", id = "") => {
        if (!id.startsWith(modelo)) id = [modelo, id].join("_");
        console.log("DELETE", id);
        return new Promise((resolve, reject) => {
            db.get(id)
                .then((doc) => {
                    resolve(db.remove(doc));
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    saveMulti: async (modelo = "", objs = []) => {
        let resultados = [];
        for (const obj of objs)
            resultados.push(await metodosCRUD.save(modelo, obj));
        return resultados;
    },
    deleteMulti: async (modelo = "", objs = []) => {
        let resultados = [];
        for (const obj of objs)
            resultados.push(await metodosCRUD.delete(modelo, obj));
        return resultados;
    },
};
const metodosArchivos = {
    upload: (
        identificador = "#archivo",
        devolver = "base64", //retrocompat
        nombreAlternativo = null,
        omitirHash = false,
        webp = false,
        maxSize = 500,
    ) => {
        console.log("UL", {
            identificador,
            devolver,
            nombreAlternativo,
            omitirHash,
            webp,
            maxSize,
        });
        const save = metodosCRUD.save;
        return new Promise(async (resolve, reject) => {
            if (["binario", "base64"].indexOf(devolver) < 0) {
                console.warn(
                    "Formato de salida no permitido (" + devolver + ")",
                );
            }
            if (identificador === "") {
                reject("Falta un query selector");
            }
            const fileInput = document.querySelector(identificador).files[0];
            const fr = new FileReader();
            if (
                fileInput.type.startsWith("image") &&
                fileInput.type.indexOf("svg") < 0
            ) {
                const fr = new FileReader();

                fr.readAsDataURL(fileInput);
                fr.onloadend = async function () {
                    const dataUrl = fr.result;
                    const img = new Image();
                    img.src = dataUrl;
                    img.onload = async () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
                        let scaleFactor = Math.min(
                            maxSize / img.width,
                            maxSize / img.height,
                        );
                        if (scaleFactor > 1) scaleFactor = 1;
                        const newWidth = img.width * scaleFactor;
                        const newHeight = img.height * scaleFactor;
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);
                        const webpDataUrl = canvas.toDataURL("image/webp", 0.8);
                        console.log(
                            "WEBP!",
                            dataUrl.length,
                            webpDataUrl.length,
                        );
                        /*
                        const nombre = nombreAlternativo ?? fileInput.name;
                        const fileObj = {
                            nombre,
                            _attachments: {
                                file: {
                                    content_type: "image/webp",
                                    data: webpDataUrl.split(",")[1],
                                },
                            },
                        };
                        const u = await save("ul", fileObj);
                        resolve(u._id); //*/
                        resolve(webpDataUrl);
                    };
                };
            } else {
                fr.readAsDataURL(fileInput);
                fr.onloadend = async () => {
                    const nombre = nombreAlternativo ?? fileInput.name;
                    const data = fr.result.split(",")[1];
                    const fileObj = {
                        nombre,
                        _attachments: {
                            file: {
                                content_type: fileInput.type,
                                data,
                            },
                        },
                    };
                    const u = await save("ul", fileObj);
                    resolve(u._id);
                };
            }
        });
    },

    uploadwebp: (
        handler = "#archivo",
        devolver = "binario",
        nombreAlternativo = null,
        //omitirHash = false,
        //webp = false
    ) => metodosArchivos.upload(handler, devolver, nombreAlternativo),
    thumbnailer: async (
        handler = "#foto",
        ratio = 1,
        nombre = "foto",
        tamano = 128,
    ) => {
        window.toast("POR IMPLEMENTAR. Subiendo foto normal.");
        if (!handler.startsWith("#")) handler = "#" + handler;
        console.log("thumbnail por implementar", {
            handler,
            ratio,
            nombre,
            tamano,
        });
        return this.upload(handler, "base64", nombre, false, true, tamano);
    },
    imagen2webp: async (str = "") => {
        const u = await metodosCRUD.get("imagen", str);
        console.log("imagen2webp", str);
        return { data: u };
    },
};
const metodosOtros = {
    quickpdf: async (
        html = "",
        pdfOptions = {
            format: "a4", // Si se establece width y height, hay que omitir format
            margin: {
                top: "10mm",
                bottom: "10mm",
                left: "10mm",
                right: "10mm",
            },
        },
        usePuppeteer = true,
        customCss = "",
        guardarPDF = false,
    ) => {
        let url = "https://app.haakon.cc:8083/node/html2pdf";
        //
        if (customCss == "")
            customCss =
                "@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');";
        customCss += "* { font-family: 'Ubuntu', sans-serif; }"; //*/
        //
        return axios.post(
            url,
            {
                html,
                pdfOptions,
                usePuppeteer,
                customCss,
                guardarPDF,
            },
            {
                headers: {
                    "wst-appkey": appKey,
                    token: metodosBase.token(),
                },
            },
        );
    },
};
export default {
    tipo: "local",
    ...metodosBase,
    ...metodosCRUD,
    ...metodosArchivos,
    ...metodosOtros,
    modoProduccion,
    prefijo,
};
</script>

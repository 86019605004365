let rutaLocal = "@/apps/Tarea/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/possimple",
    name: "TPV Simple",
    component: () =>
      import(/* webpackChunkName: "possimple" */ rutaLocal + "Gui"),
  },
  {
    path: "/possimple/entradassalidas",
    name: "Entradas y salidas de almacén",
    component: () =>
      import(
        /* webpackChunkName: "possimpleentradassalidas" */ rutaLocal +
          "MovimientosAlmacen"
      ),
  },
  {
    path: "/possimple/corte",
    name: "Corte de ventas",
    component: () =>
      import(/* webpackChunkName: "poscorterapido" */ rutaLocal + "Corte"),
  },
  {
    path: "/possimple/cortecrear",
    name: "Hacer corte",
    component: () =>
      import(/* webpackChunkName: "poscortecrear" */ rutaLocal + "CorteCrear"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "TPV Simple",
  // descripcion aparece en el menú
  descripcion: "Punto de venta para mostrador",
  tarifa: 399,
  // Ícono principal
  icono: "logoIonic",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear ventas",
    "Puede borrar ventas",
    "Puede borrar partidas",
    "Puede gestionar catálogos",
    "Puede gestionar almacén",
    "Puede ver reportes",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/possimple",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/possimple",
      label: "TPV simple",
      permiso: "Puede crear ventas",
      icono: "logoIonic",
    },
    /*{
      ruta: "/possimple/entradassalidas",
      label: "Entradas y salidas",
      permiso: "Puede crear ventas",
      icono: "cubeOutline",
    }, 
    {
      ruta: "/erp/ordenescompra",
      label: "Órdenes de compra",
      permiso: "Puede crear ventas",
      icono: "priceTagsOutline",
    },//*/
    { label: "" },
    {
      ruta: "/erp/catalogos",
      label: "Catálogos",
      permiso: "Puede gestionar catálogos",
      icono: "listCircleOutline",
    },
    {
      ruta: "/erp/cortes",
      label: "Cortes",
      permiso: "Puede ver reportes",
      icono: "statsChartOutline",
    },
    {
      ruta: "/almacen",
      label: "Inventario",
      permiso: "Puede gestionar almacén",
      icono: "cubeOutline",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  variables: [
    {
      label: "Usar QZ Tray",
      name: "usarQZTray",
      type: "binary",
    },
    {
      label: "IVA global de los productos",
      name: "ivaGlobal",
      type: "number",
      default: 0,
    },
    {
      label: "Nombre de la impresora",
      name: "qzTrayImpresora",
      type: "text",
    },
    {
      label: "Ancho de renglon",
      name: "qzTrayAncho",
      type: "select",
      noOrdenar: true,
      options: [
        {
          label: "48 (Epson 58mm)",
          value: 48,
        },
        {
          label: "22 (Gainsha, ECLine, Xprinter)",
          value: 22,
        },
      ],
    },
    {
      label: "Leyenda al pie de ticket",
      name: "usarPdf58mmLeyenda",
      type: "textarea",
    },
    {
      label: "Omitir logo en tickets",
      name: "visualizadorProyectoOmitirLogo",
      type: "binary",
    },
    {
      label: "Usar precios personalizados por cliente",
      name: "preciosPorCliente",
      type: "binary",
    },
  ],
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

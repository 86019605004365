let rutaLocal = "@/apps/ERP3/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/erp3",
    name: "ERP3",
    component: () =>
      import(/* webpackChunkName: "erp3menu" */ rutaLocal + "Lista"),
    children: [
      {
        path: "",
        name: "ERP3Home",
        component: () =>
          import(/* webpackChunkName: "erp3home" */ rutaLocal + "Home"),
      },
      {
        path: "ingresos",
        name: "ERP3Ingresos",
        component: () =>
          import(
            /* webpackChunkName: "erp3ingresos" */ rutaLocal + "Transacciones"
          ),
        props: { modopagos: "todos", modotransaccion: "ingresos" },
      },
      {
        path: "cuentasporcobrar",
        name: "ERP3CxC",
        component: () =>
          import(
            /* webpackChunkName: "erp3ingresos2" */ rutaLocal + "Transacciones"
          ),
        props: { modopagos: "pendientes", modotransaccion: "ingresos" },
      },
      {
        path: "egresos",
        name: "ERP3Egresos",
        component: () =>
          import(
            /* webpackChunkName: "erp3egresos" */ rutaLocal + "Transacciones"
          ),
        props: { modopagos: "todos", modotransaccion: "egresos" },
      },
      {
        path: "cuentasporpagar",
        name: "ERP3CxP",
        component: () =>
          import(
            /* webpackChunkName: "erp3egresos2" */ rutaLocal + "Transacciones"
          ),
        props: { modopagos: "pendientes", modotransaccion: "egresos" },
      },
      {
        path: "impuestos",
        name: "ERP3Impuestos",
        component: () =>
          import(
            /* webpackChunkName: "erp3impuestos" */ rutaLocal + "Impuestos"
          ),
      },
      {
        path: "config",
        redirect: "/erp/catalogos",
      },
    ],
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "ERP 3",
  // descripcion aparece en el menú
  descripcion: "ERP simplificado",
  tarifa: 0,
  // Ícono principal
  icono: "erp",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: ["Puede usar el ERP"],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/erp3",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/erp3",
      label: "ERP 3",
      permiso: "Puede usar el ERP",
      icono: "erp",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

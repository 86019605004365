<template>
  <ion-app>
    <ion-split-pane content-id="main-content" when="xl" :disabled="true">
      <HbMenu v-if="yo._id" />
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  toastController,
  alertController,
} from "@ionic/vue";
import {
  defineComponent,
  //,computed
} from "vue";
import {
  closeCircleOutline, //informationCircleOutline
} from "ionicons/icons";
import HbMenu from "@/common/Menu/Menu";

window.toast = async (
  message = "",
  duration = 2000,
  color = "secondary",
  position = "top"
) => {
  const toast = await toastController.create({
    message,
    duration,
    color,
    position,
    //icon: informationCircleOutline,
    buttons: [
      {
        icon: closeCircleOutline,
        role: "cancel",
        handler: () => {
          console.log("Cancel clicked");
        },
      },
    ],
  });
  await toast.present();
};
window.alert2 = window.alert;
window.alert = async function (
  message = "",
  header = "Aviso",
  okButton = "OK"
) {
  try {
    const alert = await alertController.create({
      cssClass: "my-custom-class",
      header,
      message,
      buttons: [okButton],
    });
    await alert.present();
  } catch (e) {
    return window.alert2(message);
  }
};
// Custom prompt function
window.promptAsync = async function (
  message = "",
  inputType = "text",
  header = "Prompt",
  okButton = "OK",
  cancelButton = "Cancel"
) {
  return new Promise(async (resolve, reject) => {
    try {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header,
        message,
        inputs: [
          {
            name: "promptInput",
            type: inputType,
            placeholder: message,
          },
        ],
        buttons: [
          {
            text: cancelButton,
            role: "cancel",
            handler: () => {
              reject(null); // Handle cancel action
            },
          },
          {
            text: okButton,
            handler: (data) => {
              resolve(data.promptInput); // Resolve with user input
            },
          },
        ],
      });
      await alert.present();
    } catch (e) {
      console.error("Error showing custom prompt:", e);
      return prompt(message);
    }
  });
};

import Api from "@/common/Api/Api";
const yo = Api.fetchLocal("yo") || {};
let empresa = Api.fetchLocal("empresadb") || {};
async function actualizarEmpresa() {
  empresa = Api.fetchLocal("empresadb") || {};
  console.log("Hola, " + (yo.nombre || "Invitado"), empresa.nombre);
  try {
    empresa = (await Api.get("empresa"))[0];
    Api.saveLocal("empresadb", empresa);
    //console.log("empresa? get", empresa);
  } catch (e) {
    console.error("No info de empresa!"), e;
  }
}
export default defineComponent({
  name: "IttonApp",
  components: {
    IonApp,
    HbMenu,
    IonRouterOutlet,
    IonSplitPane,
  },
  async mounted() {
    const loginRoute = "/login";
    console.log("r", this.$route.path);
    if (
      !(yo._id && yo.jwt) &&
      this.$route.path != loginRoute &&
      // Agregar el login de servicios para clientes, proveedores, etc
      !this.$route.path.startsWith("/servicios") &&
      // Manuales
      !this.$route.path.startsWith("/manual") &&
      // Pagina de autofacturacion
      !this.$route.path.startsWith("/facturacion")
    ) {
      this.$router.push(loginRoute);
    } else {
      actualizarEmpresa();
    }
    await Api.wait(1000);
    let empresa = Api.fetchLocal("empresadb") ?? {};
    if (
      (empresa.nombre ?? "") == "" &&
      yo.nombre &&
      this.$route.path != "/config/empresa"
    ) {
      console.log("empresa?");
      Api.saveLocal("empresadb", {
        nombre: Api.fetchLocal("empresastr"),
      });
      alert("Proporciónanos tus datos de empresa para continuar");
      await Api.wait(1000);
      this.$router.push("/config/empresa");
    }
    // Firebase!
    try {
      const tema = localStorage.getItem("tema") ?? "";
      if (tema == "")
        throw new Error("No hay tema configurado. No habrá notificaciones.");
      let fb = await fetch(`./google-services-${tema}.json`);
      fb = await fb.json();
      console.log({ fb });
    } catch (e) {
      console.error(e);
    }
  },
  data() {
    return {
      yo,
      activarMenuInteligente: false, //Api.fetchLocal("activarMenuInteligente") && true,
    };
  },
  watch: {
    "$route.path": function (a) {
      console.log("$path", a);
      this.activarMenuInteligente =
        Api.fetchLocal("activarMenuInteligente") && true;
      this.$forceUpdate();
    },
    estoyEnDashboard: function (val) {
      return val.startsWith("/dashboard");
    },
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 2px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 16px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 14px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>

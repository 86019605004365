let rutaLocal = "@/apps/Hersteller/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/hersteller",
    name: "Hersteller",
    component: () =>
      import(/* webpackChunkName: "hersteller" */ rutaLocal + "Home"),
  },
  {
    path: "/hersteller/planner",
    name: "HTPlanner",
    component: () =>
      import(/* webpackChunkName: "htplanner" */ rutaLocal + "Planner"),
  },
  {
    path: "/hersteller/planner/:id",
    name: "HTPlannerId",
    component: () =>
      import(
        /* webpackChunkName: "htplannerid" */ rutaLocal + "Proyecto/Proyecto"
      ),
    props: true,
  },
  {
    path: "/hersteller/compras",
    name: "HTListaCompras",
    component: () =>
      import(
        /* webpackChunkName: "htlistacompras" */ rutaLocal + "ListaCompras"
      ),
  },
  {
    path: "/hersteller/actividades",
    name: "HTListaActividades",
    component: () =>
      import(
        /* webpackChunkName: "htlistaactividades" */ rutaLocal +
          "ListaActividades"
      ),
  },
  {
    path: "/hersteller/reportes",
    name: "HTReportes",
    component: () =>
      import(/* webpackChunkName: "htreportes" */ rutaLocal + "Reportes"),
  },
  {
    path: "/hersteller/catalogos",
    name: "HTCatalogoCatalogos",
    component: () =>
      import(
        /* webpackChunkName: "htcatalogos" */ rutaLocal + "Catalogos/Catalogos"
      ),
  },
  {
    path: "/hersteller/productos",
    name: "HTCatalogoProductos",
    component: () =>
      import(
        /* webpackChunkName: "htproductos" */ rutaLocal + "Catalogos/Productos"
      ),
  },
  {
    path: "/hersteller/productos/:id",
    name: "HTCatalogoProductoId",
    component: () =>
      import(
        /* webpackChunkName: "htproductoid" */ rutaLocal + "Catalogos/Producto"
      ),
    props: true,
  },
  {
    path: "/hersteller/clientes",
    redirect: "/erp/catalogos/clientes",
  },
  {
    path: "/hersteller/proveedores",
    redirect: "/erp/catalogos/proveedores",
  },
  {
    path: "/hersteller/ordenes",
    redirect: "/erp/ordenescompra",
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Planeación",
  // descripcion aparece en el menú
  descripcion: "Planeación y suministro de manufactura",
  tarifa: 3999,
  // Ícono principal
  //icono: "calculator",
  icono: "planeacion",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear tareas",
    "Puede eliminar tareas",
    "Puede ver tareas de otros usuarios",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/hersteller",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/hersteller",
      label: "Planeación de proyectos",
      permiso: "Puede crea tareas",
      icono: "calculatorOutline",
    },
    {
      ruta: "/hersteller/ordenes",
      label: "Órdenes de compra",
      permiso: "Puede crea tareas",
      icono: "pricetagsOutline",
    },
    {
      ruta: "/hersteller/reportes",
      label: "Reportes",
      permiso: "Puede crea tareas",
      icono: "statsChartOutline",
    },
    {
      ruta: "/hersteller/catalogos",
      label: "Catálogos",
      permiso: "Puede crea tareas",
      icono: "cube",
    },
  ],
  variables: [
    {
      label: "Evaluables predeterminados (separados por comas)",
      name: "evaluablesPredeterminados",
      type: "text",
      required: false,
    },
    {
      label: "Nombre de evaluable singular",
      name: "evaluableNombreSingular",
      placeholder: "Producto",
      type: "text",
    },
    {
      label: "Nombre de evaluable plural",
      name: "evaluableNombrePlural",
      placeholder: "Productos",
      type: "text",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
  /*variables: [{
      label: 'Texto',
      name: 'texto',
      type: 'text',
      required: false,
      detail: false
    },
    {
      label: 'Numero',
      name: 'numero',
      type: 'number',
      step: 0.1
    },
    {
      label: 'Opciones',
      name: 'opciones',
      type: 'select',
      options: ['opcion 1', 'opcion 2', 'opcion 3']
    }, {
      label: 'Imagen',
      name: 'uploadimagen'
    }
  ]//*/
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

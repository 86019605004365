let rutaLocal = "@/apps/Forms/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/forms",
    redirect: "/forms/documentos",
  },
  {
    path: "/forms/documentos",
    name: "FormsLista",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "Lista"),
  },
  {
    path: "/forms/documentos/:id",
    name: "FormsDocumento",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "Documento"),
    props: true,
  },
  {
    path: "/forms/catalogo",
    name: "FormsCatalogo",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "Catalogo"),
  },
  {
    path: "/forms/catalogo/:id",
    name: "FormsCatalogoId",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "Form"),
    props: true,
  },
  {
    path: "/forms/reportes",
    name: "FormsReportes",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "Reportes"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Forms",
  // descripcion aparece en el menú
  descripcion: "Documentos dinámicos",
  tarifa: 400,
  // Ícono principal
  icono: "fileTrayFull",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: ["Puede gestionar formas", "Puede gestionar documentos"],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/forms",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/forms/documentos",
      label: "Documentos dinámicos",
      permiso: "Puede gestionar documentos",
      icono: "fileTrayFullOutline",
    },
    {
      ruta: "/forms/catalogo",
      label: "Catálogo de tipos de documento",
      permiso: "Puede gestionar formas",
      icono: "appsOutline",
    },
    {
      ruta: "/forms/reportes",
      label: "Reportes",
      permiso: "Puede ver reportes",
      icono: "barChartOutline",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
  /*variables: [{
      label: 'Texto',
      name: 'texto',
      type: 'text',
      required: false,
      detail: false
    },
    {
      label: 'Numero',
      name: 'numero',
      type: 'number',
      step: 0.1
    },
    {
      label: 'Opciones',
      name: 'opciones',
      type: 'select',
      options: ['opcion 1', 'opcion 2', 'opcion 3']
    }, {
      label: 'Imagen',
      name: 'uploadimagen'
    }
  ]//*/
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

let rutaLocal = "@/apps/PCAssets/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/pcassets",
    name: "PCAssets",
    component: () =>
      import(/* webpackChunkName: "tareaslista" */ rutaLocal + "PcAssets"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Uso de PCs",
  // descripcion aparece en el menú
  descripcion: "Inventario y bitácora de uso de sus PC",
  tarifa: 1499,
  // Ícono principal
  icono: "laptop",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: ["Puede gestionar inventario de PCs conectadas a la red"],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/pcassets",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/pcassets",
      label: "Uso de PCs",
      permiso: "Puede gestionar inventario de PCs conectadas a la red",
      icono: "laptopOutline",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
  variables: [
    {
      label: "URL de descarga del software de trazabilidad de PCs",
      name: "rutaSoftwarePCAssets",
      type: "url",
      required: true,
      detail: false,
    },
  ],
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/

<script>
import ApiOffline from "./ApiOffline";
import ApiLocalStorage from "./ApiLocalStorage";
import axios from "axios";
import sha1 from "sha1";
import { v4 as uuidv4 } from "uuid";
import HelperFunctions from "./HelperFunctions.vue";
axios.defaults.headers.common["Content-Type"] = "application/json";
const prefijo = "ittoniapp3";
const modoProduccion = window.location.href.indexOf("localhost") < 0;
//
let dominioMaestro = "app.haakon.cc";
if (window.location.href.indexOf("mallainsa.mx") >= 0)
    dominioMaestro = "app.mallainsa.mx";
const puerto = 8083;
let cdn = "";
const usarCdnDeLaApi = true;
try {
    if (!usarCdnDeLaApi) throw new Error("Usando CDN default");
    const ApiStr = localStorage.getItem(prefijo + ".servidor");
    const ApiUrl = new URL(ApiStr);
    const port = ApiUrl.port;
    const parteRemovible = ApiStr.split(port)[1];
    cdn = ApiStr.replace(parteRemovible, "") + "/files";
} catch (e) {
    cdn = "https://cdn.haakon.cc";
    console.error("Fallback CDN", cdn);
}
// Para ciertos clientes y aplicaciones, hay que configurar una URL custom para la API
let dominiosCustom = [
    {
        dominio: "app.ittoni.mx",
        dominioApiCustom: "app.haakon.cc",
        cdnCustom: "https://cdn.haakon.cc",
    },
    {
        dominio: "intranet.encorp.mx",
        dominioApiCustom: "app.encorp.mx",
        cdnCustom: "https://app.encorp.mx:8083/files",
    },
];
for (let d of dominiosCustom)
    if (window.location.href.indexOf(d.dominio) >= 0) {
        dominioMaestro = d.dominioApiCustom;
        //cdn = d.cdnCustom;
    }
//
const appKey = "haakon.app3";
let defaultTimeout = 2500; // Timeout para esperar a Axios a que responda antes de devolver error
let datos = {
    appName: "HAAKON APP",
    servidor: "https://" + dominioMaestro + ":" + puerto + "/test",
    servidorDefault: "https://" + dominioMaestro + ":" + puerto + "/test",
    servidorDominioEstandar: dominioMaestro,
    cdn,
    defaultUser: "",
    defaultPw: "",
    customTokenSource: "",
    localStorageDefaultKey: prefijo + ".yo",
    modoProduccion,
    modoOffline: false,
    localStoragePrefijo: prefijo,
    tipo: "wst",
};
let metodos = {
    wait: function (time = defaultTimeout) {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log("wait " + time);
                resolve(true);
            }, time);
        });
    },
    // Funciones globales
    ping: function (str) {
        str = str || this.servidor;
        let vm = this;
        return new Promise((resolve, reject) => {
            try {
                let tS = new Date().getTime();
                axios
                    .get(str, {
                        headers: {
                            "wst-appkey": appKey,
                            token: metodos.token(),
                        },
                    })
                    .then(
                        function (o) {
                            if (o.data && typeof o.data.serialNumber)
                                vm.uuid = o.data.serialNumber;
                            o.delta = new Date().getTime() - tS;
                            resolve(o);
                        },
                        function (e) {
                            reject(e);
                        },
                    );
            } catch (e) {
                console.error("EPING", e);
                reject(e);
            }
        });
    },
    login: async function (
        user = "",
        pass = "",
        dondeGuardarIdentidad,
        noReintentar = false,
    ) {
        dondeGuardarIdentidad =
            dondeGuardarIdentidad || datos.localStorageDefaultKey;
        //*/
        metodos.saveLocal("forzarModoOffline", false); // De otro modo, no se guarda la empresa ni se sincronizan los catálogos
        //
        console.log("LOGIN", user);
        let passOriginal = pass || "";
        if (pass.length != 40) pass = sha1(pass || "");
        let resultado = null;
        let error = null;
        let jwtable = null;
        console.info("Login", user, pass);
        try {
            console.log("Intentando auth directa...");
            let rJWT = await axios.post(
                datos.servidor + "/login",
                {
                    email: user,
                    password: pass,
                },
                {
                    headers: {
                        "wst-appkey": appKey,
                    },
                },
            );
            if (rJWT && rJWT.data && rJWT.data._id && rJWT.data.jwt) {
                metodos.saveLocal("yo", rJWT.data);
                if (
                    rJWT.data.servidor &&
                    rJWT.data.servidor != "" &&
                    rJWT.data.servidor.startsWith("http")
                ) {
                    metodos.saveLocal("servidor", rJWT.data.servidor);
                    datos.servidor = rJWT.data.servidor;
                    console.info(
                        "Encontrado servidor especificado",
                        datos.servidor,
                    );
                }
                resultado = rJWT.data;
                console.info("AUTH+JWT", resultado.email, resultado.jwt.length);
                const empresas = await metodos.get("empresa");
                if (empresas && empresas[0]) {
                    const empresa = empresas[0] ?? {};
                    console.log(
                        "apiwst login empresa?",
                        empresa.nombre || empresa,
                    );
                    metodos.saveLocal("empresadb", empresa);
                }
            } else throw new Error("Credenciales incorrectas?");
        } catch (e) {
            console.warn("No se pudo auth directa. !Reintentando?", {
                noReintentar,
            });
            if (noReintentar) {
                error = "Credenciales no encontradas";
            } else {
                console.warn(e);
                try {
                    // Si hubo exito en un inicio de sesion previo con una cuenta maestra (admin), usarla como proxy
                    let defaultUser = this.defaultUser;
                    let defaultPw = this.defaultPw;
                    if (defaultUser == "")
                        throw new Error(
                            "No hay datos de sesión predeterminada",
                        );
                    let previousDefaultUser =
                        metodos.fetchLocal("wstDefaultUser");
                    if (previousDefaultUser && previousDefaultUser.length > 0)
                        defaultUser = previousDefaultUser;
                    let previousDefaultPw = metodos.fetchLocal("wstDefaultPw");
                    if (previousDefaultPw && previousDefaultPw.length > 0)
                        defaultPw = previousDefaultPw;
                    // Obtener un JWT
                    jwtable = await this.login(
                        defaultUser,
                        defaultPw,
                        dondeGuardarIdentidad,
                        true,
                    );
                    console.log("jwtable", defaultUser, defaultPw, jwtable);
                    if (!(jwtable && jwtable.jwt)) {
                        throw new Error({
                            error: "Credenciales maestras incorrectas",
                            raw: jwtable,
                        });
                    }
                    metodos.saveLocal(dondeGuardarIdentidad, jwtable);
                    let jwt = jwtable.jwt;
                    // Buscar en la BD
                    let resultsLegacy = await metodos.find(
                        "usuario",
                        ["email,eq," + user, "password,eq," + passOriginal],
                        {
                            limit: 1,
                        },
                    );
                    let resultsLegacy2 = await metodos.find(
                        "cliente",
                        ["email,eq," + user, "password,eq," + passOriginal],
                        {
                            limit: 1,
                        },
                    );
                    resultsLegacy = resultsLegacy.concat(resultsLegacy2);
                    console.log("resultsLegacy", resultsLegacy);
                    if (
                        resultsLegacy &&
                        resultsLegacy[0] &&
                        resultsLegacy[0]._id
                    ) {
                        console.info("AUTH+Legacy", resultsLegacy);
                        // Permitir al usuario tener un token para entrar
                        resultsLegacy[0].jwt = jwt;
                        //toast("Concedido JWT para", resultsLegacy[0].email)
                        //
                        metodos.saveLocal(
                            dondeGuardarIdentidad,
                            resultsLegacy[0],
                        );
                        resultado = resultsLegacy[0];
                    } else {
                        console.error("EAUTH", resultsLegacy);
                    }
                } catch (e) {
                    error = e;
                }
            }
        }
        return new Promise(async (resolve, reject) => {
            await metodos.wait(100);
            if (resultado && resultado._id) {
                console.log("CREDENCIALES", resultado);
                // Si está disponible info de una empresa, guardarla de una vez
                try {
                    let empresaExistente = (await metodos.get("empresa")) || [];
                    console.log("");
                    if (empresaExistente && empresaExistente[0])
                        metodos.saveLocal(
                            "vueonic6.empresadb",
                            empresaExistente[0],
                        );
                    //
                } catch (e) {
                    console.error(e);
                }
                resolve(resultado);
            } else {
                console.error("CREDENCIALES", resultado);
                reject({
                    error: "Error de autentificación",
                    raw: error,
                });
            }
        });
    },
    logout: async function () {
        metodos.deleteLocal("yo");
        metodos.deleteLocal("menuArbol");
        metodos.deleteLocal("posSimpleCacheMetodosPago");
        // borrar los datos locales
        for (let key in localStorage) {
            // console.log("Comprobando", key);
            if (key.startsWith(prefijo) && key.split(".").length >= 3) {
                localStorage.removeItem(key);
                // console.log("Eliminado", key);
            }
        }
        //
        try {
            window.toast("Sesión cerrada");
            //let empresastr = metodos.fetchLocal("empresastr");
            let rutaLogin = metodos.fetchLocal("rutaLogin") || "/login";
            console.log("rutaLogin?", rutaLogin);
            //if (empresastr && empresastr != "") rutaLogin += "/" + empresastr;
            rutaLogin = "#" + rutaLogin;
            window.location.assign(rutaLogin);
            await metodos.wait(2000);
        } catch (e) {
            console.error(e);
        }
        window.location.reload();
    },
    uuid: function () {
        return uuidv4().replace(/-/g, "").substring(0, 24);
    },
    token: function () {
        let t = "",
            k = {};
        try {
            k = metodos.fetchLocal("yo");
            t = k.jwt || "";
            if (t == null || t == "") throw new Error("EHB3TOKENNOEXISTE");
        } catch (e) {
            // No se pudo recuperar el token
            //console.error("etoken pasando a wst", e);
            k = JSON.parse(localStorage.getItem("wst.yo"));
            t = k ? k.jwt : "";
        }
        return t;
    },
    setServer(str = "") {
        datos.servidor = str;
        metodos.saveLocal("servidor", datos.servidor);
        return true;
    },
    getServer: () => datos.servidor,
    setEmpresa(str = "") {
        let servidor = datos.servidorDefault.replace("test", str);
        return metodos.setServer(servidor);
    },
    // Funciones de almacenamiento local
    saveLocal: async function (lugar = "", obj) {
        try {
            if (typeof obj == "object")
                localStorage.setItem(
                    prefijo + "." + lugar,
                    JSON.stringify(obj),
                );
            else localStorage.setItem(prefijo + "." + lugar, obj);
        } catch (e) {
            console.error("ESAVELOCAL", e);
        }
        return true;
    },
    fetchLocal: function (lugar = "") {
        lugar = prefijo + "." + lugar;
        let r = null;
        try {
            r = JSON.parse(localStorage.getItem(lugar));
        } catch (e) {
            r = localStorage.getItem(lugar);
        }
        return r;
    },
    deleteLocal: function (lugar = "") {
        console.log("deleteLocal", lugar);
        localStorage.removeItem(prefijo + "." + lugar);
        return true;
    },
    // Funciones de almacenamiento en Wanshitong
    get: function (modelo = "", id = "", anularTimeout = false, subset) {
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("get forzarModoOffline");
        return new Promise(async (resolve, reject) => {
            let ruta = datos.servidor + "/get/" + modelo;
            console.log("GET", modelo, id, ruta);
            if (id != "") {
                ruta += "/" + id;
            }
            ruta += "?ts=" + new Date().getTime();
            if (subset && typeof subset == "object")
                ruta += "&subset=" + btoa(JSON.stringify(subset));
            //else {
            let options = {
                headers: {
                    "wst-appkey": appKey,
                    token: metodos.token(),
                },
            };
            if (id != "" && !anularTimeout && defaultTimeout != 0) {
                options.timeout = defaultTimeout;
                //console.log("Timeout", id, options)
            }
            try {
                let o = await axios.get(ruta, options); //.then(function(o) {
                if (o && o.data) resolve(o.data);
                else throw new Error("No hay datos para mostrar");
            } catch (e) {
                console.error("EAPIGET " + modelo, e);
                reject(e);
            }
        });
    },
    find: function (modelo = "", params, adicional) {
        modelo = modelo || "";
        params = params || Array();
        adicional = adicional || Object();
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("find forzarModoOffline");
        if (!datos.modoOffline) {
            let vm = this;
            return new Promise((resolve, reject) => {
                let resultado = [];
                let enCache = false;
                let ruta = datos.servidor + "/get/" + modelo + "?";
                if (typeof params == "string") params = [params];
                if (params.length > 0) {
                    params.forEach(function (param) {
                        if (typeof param == "string")
                            ruta += "filters[]=" + param + "&";
                    });
                }
                if (typeof adicional.limit != "undefined")
                    ruta += "limit=" + adicional.limit + "&";
                if (typeof adicional.skip != "undefined")
                    ruta += "skip=" + adicional.skip + "&";
                if (typeof adicional.math != "undefined")
                    ruta += "math=" + adicional.math + "&";
                if (typeof adicional.sort != "undefined") {
                    for (let s in adicional.sort) {
                        ruta += "sort=" + adicional.sort[s] + "&";
                    }
                }
                if (typeof adicional.subset != "undefined")
                    ruta +=
                        "subset=" +
                        btoa(JSON.stringify(adicional.subset)) +
                        "&";
                if (adicional.fields?.length > 0)
                    ruta += "fields=" + adicional.fields.join(",") + "&";
                // Determinar si la consulta fue hecha previamente
                let candidato = "";
                candidato = localStorage.getItem("wstRuta." + btoa(ruta));
                if (
                    candidato &&
                    candidato.startsWith("[") &&
                    candidato.length > 2 &&
                    vm.tiempoCache > 0
                ) {
                    enCache = true;
                    let listaCache = JSON.parse(candidato);
                    console.log(
                        "Consulta en caché. Rescatando.",
                        listaCache.length,
                    );
                    console.log(ruta);
                    for (let c in listaCache) {
                        let id = listaCache[c];
                        if (vm.cache[modelo] && vm.cache[modelo][id])
                            resultado.push(vm.cache[modelo][id]);
                        else enCache = false;
                    }
                    console.warn("Cache", resultado);
                    if (enCache) resolve(resultado);
                }
                let options = {};
                if (adicional?.timeout) options.timeout = adicional.timeout;
                // Hacer la consulta real
                try {
                    axios
                        .get(ruta, {
                            headers: {
                                "wst-appkey": appKey,
                                token: metodos.token(),
                            },
                            options,
                        })
                        .then(function (o) {
                            resolve(o.data);
                        })
                        .catch(function (e) {
                            console.error(e);
                            reject(e);
                        });
                } catch (e) {
                    console.error("EAPIFIND", e);
                    reject(e);
                }
            });
        } else {
            return ApiOffline.find(modelo, params, adicional);
        }
    },
    save: function (modelo = "", objeto, forzarbd = true) {
        modelo = modelo || "";
        objeto = objeto || {};
        //console.log("Save", modelo, objeto)
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("save forzarModoOffline");
        if (typeof objeto.__v != "undefined") delete objeto.__v;
        if (typeof objeto.jwt != "undefined") delete objeto.jwt;
        if (!datos.modoOffline || forzarbd) {
            let vm = this;
            if (!objeto.fecha) objeto.fecha = new Date().getTime();
            return new Promise((resolve, reject) => {
                if (objeto == null || modelo == "")
                    reject(
                        "Debes especificar un modelo y un objeto a actualizar",
                    );
                let ruta = datos.servidor + "/upsert/" + modelo;
                if (!objeto._id?.length) {
                    // Insertar
                    axios
                        .post(ruta, objeto, {
                            headers: {
                                "wst-appkey": appKey,
                                token: metodos.token(),
                                "Content-Type": "application/json",
                            },
                        })
                        .then(
                            function (o) {
                                console.log("Insertado", modelo, o.data.objeto);
                                resolve(o.data.objeto);
                            },
                            function (e) {
                                vm.errorHandler(e);
                                reject(e);
                            },
                        );
                } else {
                    // Actualizar
                    ruta += "/" + objeto._id;
                    axios //.put
                        .post(ruta, objeto, {
                            headers: {
                                "wst-appkey": appKey,
                                token: metodos.token(),
                                "Content-Type": "application/json",
                            },
                        })
                        .then(
                            function (o) {
                                console.log("Actualizado", modelo, o.data);
                                resolve(o.data.objeto);
                            },
                            function (e) {
                                vm.errorHandler(e);
                                reject(e);
                            },
                        );
                }
            });
        } else return ApiOffline.save(modelo, objeto);
    },
    saveMulti: async function (modelo = "", objetos = [], chunkSize = 20) {
        console.log("saveMulti", modelo, objetos.length, chunkSize);
        const ruta = datos.servidor + "/upsertmulti/" + modelo;
        objetos = (objetos || []).filter((o) => o._id);
        if (objetos == [] || modelo == "") return false;
        console.log("limpiando...");
        for (let id in objetos) {
            if (typeof objetos[id].__v != "undefined") delete objetos[id].__v;
            if (typeof objetos[id].jwt != "undefined") delete objetos[id].jwt;
            if (!objetos[id].fecha) objetos[id].fecha = new Date().getTime();
        }
        const errorHandler = this.errorHandler;
        let i, j;
        let resultado = [];
        const progresable = objetos.length;
        let progresado = 0;
        console.log("partiendo en pedazos de elementos:", chunkSize);
        for (i = 0, j = objetos.length; i < j; i += chunkSize) {
            const chunk = objetos.slice(i, i + chunkSize);
            resultado.push(
                await axios
                    //.put
                    .post(ruta, chunk, {
                        headers: {
                            "wst-appkey": appKey,
                            token: metodos.token(),
                            "Content-Type": "application/json",
                        },
                    })
                    .then(
                        function (o) {
                            //console.log("saveMulti insertados", modelo, o.data);
                            progresado += o.data.objetos.length;
                            return o.data;
                        },
                        function (e) {
                            errorHandler(e);
                            return false;
                        },
                    ),
            );
            console.log("saveMulti progreso", modelo, progresado, progresable);
        }
        return resultado;
    },
    delete: function (modelo = "", id) {
        modelo = modelo || "";
        id = id || null;
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("delete forzarModoOffline");
        if (!datos.modoOffline) {
            let ruta = datos.servidor + "/" + modelo + "/" + id;
            if (typeof ApiLocalStorage != "undefined")
                ApiLocalStorage.delete(modelo, id);
            return axios.delete(ruta, {
                headers: {
                    "wst-appkey": appKey,
                    token: metodos.token(),
                },
            });
        } else return ApiOffline.delete(modelo, id);
    },
    deleteMulti: function (modelo = "", lista) {
        lista = lista || [];
        modelo = modelo || "";
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("deletemulti forzarModoOffline");
        let ruta =
            datos.servidor + "/multi/" + modelo + "/" + JSON.stringify(lista);
        axios.delete(ruta, {
            headers: {
                "wst-appkey": appKey,
                token: this.token(),
            },
        });
        return Promise.resolve(true);
    },
    dump: function (bd = "") {
        console.error("EWARNDBDUMP", new Date());
        return new Promise((resolve, reject) => {
            let ruta = datos.servidor + "/dump";
            if (bd != "") ruta += "/" + bd;
            axios
                .post(ruta, null, {
                    headers: {
                        "wst-appkey": appKey,
                        token: metodos.token(),
                    },
                })
                .then(function (p) {
                    let o = p.data || {};
                    if (!o.error) resolve(o);
                    else reject(o);
                });
        });
    },
    // Manipulacion de archivos e imagenes
    upload: function (
        handler = "#archivo",
        devolver = "",
        nombreAlternativo = null,
        omitirHash = false,
        webp = true,
    ) {
        return new Promise((resolve, reject) => {
            if (["", "binario", "texto", "base64"].indexOf(devolver) == -1)
                reject(
                    'Valor de devolución no valido ("", binario, texto o base64)',
                );
            else if (handler == "")
                reject("Debes especificar un query DOM en el primer argumento");
            var identificador = handler;
            let h = document.querySelector(identificador).files[0];
            console.log("Cargando archivo del identificador", identificador);
            var fr = new FileReader();
            if (devolver == "base64") fr.readAsDataURL(h);
            else if (devolver == "texto") fr.readAsText(h, "utf-8");
            // if(devolver=='' || devolver=='binario')
            else fr.readAsBinaryString(h);
            fr.onloadend = function () {
                let binario = fr.result;
                console.log("Bin", handler, binario.length);
                let ruta = datos.servidor + "/upload";
                if (devolver != "") resolve(binario);
                else
                    axios
                        .post(
                            ruta,
                            {
                                archivo: btoa(binario),
                                tipo: h.type,
                                nombre: h.name,
                                omitirHash,
                                nombreAlternativo,
                                webp: webp == true ? 1 : 0,
                            },
                            {
                                headers: {
                                    "wst-appkey": appKey,
                                    token: metodos.token(),
                                    "Content-Type": "application/json",
                                },
                            },
                        )
                        .then(
                            (o) => {
                                resolve(o.data.filename);
                            },
                            (e) => {
                                reject(e);
                            },
                        );
            };
            console.info("FileReader", h);
        });
    },
    uploadwebp: function (handler = "#archivo", nombreAlternativo = null) {
        const convertirAWebP = true;
        const noOmitirHash = false;
        return metodos.upload(
            handler,
            "",
            nombreAlternativo,
            noOmitirHash,
            convertirAWebP,
        );
    },
    obtenerArchivo: async function (file = "", urlGlobal = false) {
        const url = urlGlobal ? file : datos.cdn + "/" + file;
        file = (
            await metodos.proxied2({
                url,
                method: "get",
                responseType: "arraybuffer",
            })
        ).data.data;
        //file = btoa(String.fromCharCode(...new Uint8Array(file)));
        file = "0," + file;
        return file;
    },
    removeFile: function (nombre) {
        if (!(nombre && nombre != ""))
            return Promise.reject("Debe especificar el nombre del archivo");
        else {
            let ruta = datos.servidor + "/removefile/" + nombre;
            return axios.post(ruta, null, {
                headers: {
                    "wst-appkey": appKey,
                    token: metodos.token(),
                },
            });
        }
    },
    rotator: function (foto) {
        let vm = this;
        return new Promise((resolve, reject) => {
            if (foto.indexOf(vm.cdn) >= 0)
                foto = foto.replace(vm.cdn + "/", "");
            console.log("Pidiendo rotación de", foto);
            if (!foto || foto == "")
                reject({
                    error: "No fue especificada la foto a girar",
                });
            axios
                .post(
                    datos.servidor + "/rotator",
                    {
                        img: foto,
                    },
                    {
                        headers: {
                            "wst-appkey": appKey,
                            token: metodos.token(),
                            "Content-Type": "application/json",
                        },
                    },
                )
                .then((p) => {
                    if (p && p.data && p.data.nombre) resolve(p.data.nombre);
                    else
                        reject({
                            error: "No se pudo obtener la nueva imagen rotada",
                            raw: p,
                        });
                })
                .catch((e) => {
                    reject({
                        error: "No se pudo recuperar la imagen rotada",
                        raw: e,
                    });
                });
        });
    },
    thumbnailer: function (handler, ratio, nombre, tamano, devolver) {
        let vm = this;
        devolver = devolver || "";
        ratio = ratio || 1;
        nombre = nombre || "";
        tamano = tamano || 500;
        return new Promise((resolve, reject) => {
            if (["", "binario", "base64"].indexOf(devolver) == -1)
                reject('Valor de devolución no valido ("", binario o base64)');
            var identificador = "#" + handler;
            let h = document.querySelector(identificador).files[0];
            console.log("Cargando archivo del identificador", identificador);
            var fr = new FileReader();
            if (devolver == "base64") fr.readAsDataURL(h);
            // if(devolver=='' || devolver=='binario')
            else fr.readAsBinaryString(h);
            fr.onloadend = function () {
                let binario = fr.result;
                console.log("Bin", handler, binario.length);
                let ruta = datos.servidor + "/thumbnailer";
                if (devolver != "") resolve(binario);
                else
                    axios
                        .post(
                            ruta,
                            {
                                imagen: btoa(binario),
                                tipo: h.type,
                                nombre: nombre || h.name,
                                tamano: tamano,
                                ratio: ratio,
                            },
                            {
                                headers: {
                                    "wst-appkey": appKey,
                                    token: metodos.token(),
                                    "Content-Type": "application/json",
                                },
                            },
                        )
                        .then(
                            async (o) => {
                                console.log("Thumbnailer?", o.data);
                                let img = o.data.url;
                                try {
                                    await axios.get(vm.cdn + "/" + o.data.url);
                                    resolve(img);
                                } catch (e) {
                                    img = img.replace("_thumb", "");
                                    //      resolve(await vm.upload(handler))
                                }
                                console.log("thumbnailer", img);
                                resolve(img);
                            },
                            (e) => {
                                reject(e);
                            },
                        );
            };
            console.info("FileReader", h);
        });
    },
    download: function (str = "") {
        let ruta =
            str.indexOf("http") >= 0 ? this.servidor + "/download/" + str : str;
        if (!datos.modoOffline) {
            return axios.get(ruta, {
                headers: {
                    "wst-appkey": appKey,
                    token: metodos.token(),
                },
            });
        } else {
            return Promise.reject({
                error: "No se puede usar el CDN en modo Offline",
            });
        }
    },
    downloadBase64: function (ruta) {
        let vm = this;
        if (!datos.modoOffline) {
            return new Promise((resolve, reject) => {
                vm.download(ruta).then(
                    (obj) => {
                        let header = "data:application/binary;base64,";
                        resolve(header + obj.data.data);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        } else {
            return Promise.reject({
                error: "No se puede usar el CDN en modo Offline",
            });
        }
    },
    downloadForce: function (ruta) {
        let vm = this;
        if (!datos.modoOffline) {
            vm.downloadBase64(ruta).then((blob) => {
                let a = document.createElement("a");
                a.href = blob;
                a.download = ruta;
                a.style.display = "none";
                a.style.visibility = "hidden";
                document.querySelector("body").appendChild(a);
                a.click();
            });
        } else {
            return Promise.reject({
                error: "No se puede usar el CDN en modo Offline",
            });
        }
    },
    // Email
    email: function (obj) {
        console.log("Enviando correo", obj);
        if (!datos.modoOffline) {
            return new Promise(async (resolve, reject) => {
                const paramsRequeridos = [
                    "emailOrigen",
                    "nombreOrigen",
                    "mensaje",
                    "emailDestino",
                ];
                let paramsFaltantes = [];
                for (let param of paramsRequeridos)
                    if (!obj[param]) paramsFaltantes.push(param);
                if (paramsFaltantes.length > 0)
                    reject({
                        error: "Faltan datos",
                        raw: paramsFaltantes.join(","),
                    });
                else
                    try {
                        const empresastr = metodos.fetchLocal("empresastr");
                        let ruta = (this.servidor + "/node/email").replace(
                            "/" + empresastr,
                            "",
                        );
                        axios
                            .post(ruta, obj, {
                                headers: {
                                    "wst-appkey": appKey,
                                    token: metodos.token(),
                                },
                            })
                            .then((r) => {
                                resolve(r);
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    } catch (e) {
                        console.error("ECORREO", e);
                        reject({
                            error: "No se pudo enviar el correo",
                            raw: e,
                        });
                    }
            });
        } else {
            return Promise.reject({
                error: "No se pueden enviar emails en modo Offline",
            });
        }
    },
    arrayBufferToBase64(buffer) {
        var binary = "";
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    },
    async imagen2webp(id = "") {
        const url = this.servidor + "/b64towebp/" + id;
        return await axios.get(url, {
            headers: {
                "wst-appkey": appKey,
                token: metodos.token(),
            },
        });
    },
    quickpdf: async function (
        html = "",
        pdfOptions = {
            format: "a4", // Si se establece width y height, hay que omitir format
            margin: {
                top: "10mm",
                bottom: "10mm",
                left: "10mm",
                right: "10mm",
            },
        },
        usePuppeteer = true,
        customCss = "",
        guardarPDF = false,
    ) {
        //let url = "https://app.haakon.cc:8083/node/html2pdf";
        // TODO arreglar este fix
        //if (this.servidor.indexOf("mallainsa.mx") >= 0)
        //url = "https://app.mallainsa.mx:8083/node/html2pdf";
        //
        function removeLastSlug(url) {
            const parts = url.split("/");
            parts.pop();
            return parts.join("/");
        }
        const servidorPdf = removeLastSlug(datos.servidor);
        console.log({ servidorPdf });
        let url = servidorPdf + "/node/html2pdf";
        if (customCss == "")
            customCss =
                "@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');";
        customCss += "* { font-family: 'Ubuntu', sans-serif; }"; //*/
        //
        try {
            if (false == usePuppeteer)
                throw new Error("Forzando generacion local de PDF");
            const data = await axios.post(
                url,
                {
                    html,
                    pdfOptions,
                    usePuppeteer,
                    customCss,
                    guardarPDF,
                },
                {
                    headers: {
                        "wst-appkey": appKey,
                        token: metodos.token(),
                        "Content-Type": "application/json",
                    },
                },
            );
            console.log("P", data);
            return Promise.resolve(data);
        } catch (e) {
            console.error("EPDFSRV", e);
            return { data: await metodos.slowpdf(html, pdfOptions, customCss) };
        }
    },
    slowpdf: async function (
        html = "",
        pdfOptions = {
            format: "a4", // Si se establece width y height, hay que omitir format
            margin: {
                top: "10mm",
                bottom: "10mm",
                left: "10mm",
                right: "10mm",
            },
        },
        customCss = "",
    ) {
        const { default: html2pdf } = await import("html2pdf.js");

        try {
            const m = { ...pdfOptions.margin };
            const opt = {
                //margin: pdfOptions.margin,
                margin: [m.top, m.left, m.bottom, m.right].map((size) => {
                    return parseInt(size.replace("mm", ""));
                }),
                filename: "document.pdf",
                image: { type: "webp", quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: "mm",
                    format: pdfOptions.format,
                    orientation: "portrait",
                },
            };
            console.log({ opt, customCss });
            // Generar el PDF
            const pdfBase64 = await html2pdf()
                .from(html)
                .set(opt)
                .outputPdf("datauristring");

            // Preparar el resultado en el mismo formato esperado

            return {
                status: "OK",
                pdfBase64,
                pdfUrl: null, // No se guarda el archivo en este contexto
                pdfOptionsResult: pdfOptions,
            };
        } catch (error) {
            console.error("PDF Generation Error:", error);
            return {
                error: "Error generating PDF",
                raw: error,
                reqs: "html,customCss,pdfOptions,usePuppeteer,<tailwind3styles>",
            };
        }
    },
    // libreofficetopdf
    libreofficetopdf: async function (archivo = "") {
        if (typeof archivo != "string")
            archivo = metodos.arrayBufferToBase64(archivo);
        let ruta = this.servidor + "/libreofficetopdf";
        if (archivo == "")
            return Promise.reject({
                error: "No fue especificado un archivo a convertir",
            });
        else
            return axios.post(
                ruta,
                {
                    archivo,
                },
                {
                    headers: {
                        "wst-appkey": appKey,
                        token: metodos.token(),
                    },
                },
            );
    },
    // Funciones por proxy, usando axios de lado servidor
    proxied: function (obj) {
        let ruta = datos.servidor + "/proxied";
        let params = ["url", "options", "params"];
        // params.REQUEST_TYPE
        for (let param of params)
            if (!obj[param])
                return Promise.reject("Falta el parámetro axios:", param);
        if (!datos.modoOffline) {
            return axios.post(ruta, obj, {
                headers: {
                    "wst-appkey": appKey,
                    token: metodos.token(),
                },
            });
        } else {
            return Promise.reject({
                error: "No se puede usar el proxy de aplicaciones en modo Offline",
            });
        }
    },
    proxied2: function (obj) {
        const ruta = datos.servidor + "/proxied2";
        return axios.post(ruta, obj, {
            headers: {
                "wst-appkey": appKey,
                token: metodos.token(),
            },
        });
    },
    // QR
    qrcode: function (str) {
        console.log("QR", str);
        let ruta = datos.servidor + "/qrcode";
        if (!datos.modoOffline) {
            return axios.post(
                ruta,
                {
                    cadena: str,
                },
                {
                    headers: {
                        "wst-appkey": appKey,
                        token: metodos.token(),
                    },
                },
            );
        } else {
            return Promise.reject({
                error: "No se puede usar el módulo QR en modo Offline",
            });
        }
    },
    // Geo
    geolocate: function () {
        let y = metodos.fetchLocal("yo") || {};
        if (metodos.fetchLocal("forzarModoOffline") == true)
            return Promise.reject("geolocate forzarModoOffline");
        return new Promise(async (resolve, reject) => {
            if ("geolocation" in navigator && y && y._id) {
                navigator.geolocation.getCurrentPosition(
                    async (pos) => {
                        let c = {
                            rol: y.rol || "invitado",
                            userid: y._id || "",
                            lat: pos.coords.latitude,
                            long: pos.coords.longitude,
                            date: new Date().getTime(),
                            route: window.location.hash,
                        };
                        let u = await metodos.save("geo", c);
                        console.log("Guardado geolocalizacion", u);
                        resolve(u);
                    },
                    (e) => {
                        reject({
                            error: "No se pudo obtener la ubicación",
                            raw: e,
                        });
                    },
                );
            } else {
                reject("No se pudo acceder al módulo de geolocalización");
            }
        });
    },
    //
    readBypass: function (coleccion = "") {
        const ruta = datos.servidor + "/readbypass/" + coleccion;
        return axios.post(
            ruta,
            {},
            {
                headers: {
                    "wst-appkey": appKey,
                    //token: metodos.token(),
                },
            },
        );
    },
    // Variables globales
    varGlobal(str = "", valorDefault = "") {
        let variable = valorDefault;
        let empresa = metodos.fetchLocal("empresadb") || {};
        empresa = HelperFunctions.limpiarObjetos(empresa);
        //console.log("varglobal", str, valorDefault, empresa.variables);
        if (
            empresa &&
            empresa.variables &&
            empresa.variables[str] &&
            empresa.variables[str] != ""
        )
            variable = empresa.variables[str];
        else if (empresa[str]) variable = empresa[str];
        if (variable == "true") variable = true;
        if (variable == "false") variable = false;
        return variable;
    },
    urlize: (str = "") =>
        str.startsWith("data") || str.startsWith("http")
            ? str
            : cdn + "/" + encodeURIComponent(str),
};

// Inicializar
let servidorEnDispositivo = metodos.fetchLocal("servidor");
if (servidorEnDispositivo != "") metodos.setServer(servidorEnDispositivo);

export default {
    ...datos,
    ...metodos,
};
</script>

const routes = [
  {
    path: "/config",
    component: () => import("./Config"),
  },
  {
    path: "/config/usuarios",
    component: () => import("./Usuarios"),
  },
  {
    path: "/config/usuarios/:id",
    component: () => import("./Usuario"),
    props: true,
  },
  {
    path: "/config/brandings",
    component: () => import("./Brandings"),
  },
  {
    path: "/config/brandings/:id",
    component: () => import("./Branding"),
    props: true,
  },
  {
    path: "/configmodulo/usuarios/:setmodulo",
    component: () => import("./Usuarios"),
    props: true,
  },
  {
    path: "/configmodulo/usuarios/:setmodulo/:id",
    component: () => import("./Usuario"),
    props: true,
  },
  {
    path: "/configmodulo/clientes/:setmodulo",
    component: () => import("./Clientes"),
    props: true,
  },
  {
    path: "/configmodulo/clientes/:setmodulo/:id",
    component: () => import("./Cliente"),
    props: true,
  },
  {
    path: "/config/empresa",
    component: () => import("./Empresa"),
  },
  {
    path: "/config/modulos",
    component: () => import("./ModulosAdquiridos"),
  },
  {
    path: "/config/importarexportar",
    component: () => import("./ImportarExportar"),
  },
  {
    path: "/config/borrardatos",
    component: () => import("./BorrarDatos"),
  },
];
export default routes;

let rutaLocal = '@/apps/CfdiPdf/'
rutaLocal = './'
import Api from '@/common/Api/Api'
const routes = [{
  path: '/cfdipdf',
  name: 'CfdiPdf',
  component: () => import( /* webpackChunkName: "cfdipdf" */ rutaLocal + 'CfdiPdf')
}]
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: 'CFDI a PDF',
  // descripcion aparece en el menú
  descripcion: 'Aplicación para visualizar XML de CFDI 3.3',
  // Tarifa default a cobrar
  tarifaDefault: 0,
  // Ícono principal
  icono: 'codeWorking',
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: '/cfdipdf',
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [{
    ruta: '/cfdipdf',
    label: 'CFDI a PDF',
    icono: 'codeWorking'
  }]
}
let menuArbol = Api.fetchLocal('menuArbol') || {}
menuArbol[manifiesto.nombre] = manifiesto
Api.saveLocal('menuArbol', menuArbol)
export default routes

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
let rutaLocal = "@/apps/Almacen/";
rutaLocal = "./";
import Api from "@/common/Api/Api";
const routes = [
  {
    path: "/almacen",
    name: "Almacén",
    component: () =>
      import(/* webpackChunkName: "almacenhome" */ rutaLocal + "Almacen"),
  },
  {
    path: "/almacen/inventario",
    name: "ERPIDalmaceninventario",
    component: () =>
      import(
        /* webpackChunkName: "almaceninventariootrohome" */ rutaLocal +
          "Inventario"
      ),
  },
  {
    path: "/almacen/inventario/:id",
    name: "ERPIDalmaceninventarioid",
    component: () =>
      import(
        /* webpackChunkName: "almaceninventarioid" */ rutaLocal + "Inventario"
      ),
    props: true,
  },
  {
    path: "/almacen/inventariolista",
    name: "ERPIDalmaceninventariolista",
    component: () =>
      import(
        /* webpackChunkName: "almaceninventariolista" */ rutaLocal + "Lista"
      ),
    props: true,
  },
  {
    path: "/almacen/existencias",
    name: "ERPIDalmacenexistencias",
    component: () =>
      import(
        /* webpackChunkName: "almacenexistencias" */ rutaLocal + "Existencias"
      ),
    props: true,
  },
  {
    path: "/almacen/movimientos",
    name: "ERPIDalmacenmovimientos",
    component: () =>
      import(
        /* webpackChunkName: "almacenmovimientosmanuales" */ rutaLocal +
          "Movimientos"
      ),
  },
  {
    path: "/almacen/historial",
    name: "ERPIDalmacenhistorialproductos",
    component: () =>
      import(
        /* webpackChunkName: "almacenhistorial" */ rutaLocal + "Productos"
      ),
  },
  {
    path: "/almacen/reportes",
    name: "ERPIDalmacenReportes",
    component: () =>
      import(/* webpackChunkName: "almacenreportes" */ rutaLocal + "Reportes"),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Inventarios y almacenes",
  // descripcion aparece en el menú
  descripcion: "Todo lo referente a suministros",
  tarifa: 0,
  // Ícono principal
  icono: "fileTrayStacked",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [
    "Puede crear inventarios",
    "Puede crear reportes",
    "Puede gestionar existencias",
  ],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/almacen",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/almacen/existencias",
      label: "Existencias",
      permiso: "Puede gestionar existencias",
      icono: "fileTrayStacked",
    },
    {
      ruta: "/almacen/inventario",
      label: "Hacer inventario",
      permiso: "Puede crear inventarios",
      icono: "fileTrayStacked",
    },
    {
      ruta: "/almacen/reportes",
      label: "Reportes",
      permiso: "Puede crear reportes",
      icono: "statsChart",
    },
  ],
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;
